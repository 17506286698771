import React from 'react'
import CircleContainer from './CircleContainer'
import { useMediaQuery } from '@mui/material';


const ImageContainer1 = () => {
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div className='absolute' style={{borderRadius: "35px", backgroundColor : "#69a0df", width:isMobile ? "157px" :"293px", height:isMobile ? "220px" :"403px", 
    top:isMobile ? "445px" : isTab ? "442px" :isLaptop ? "144px"  : "0px", 
    right:isMobile ? "230px" : isTab ? "371px" :isLaptop ? "322px" : "338px"}}>
        <img className='absolute bottom-0' style={{right:isMobile?"-12px" : "-20px", zIndex:10}} src="Images/guidanceSection/serious-handsome.png" alt="handsomeman" />
        <CircleContainer width={isMobile ? "16px" :"26px"} height={isMobile ? "16px" :"26px"} color={"#5291DB"} top={isMobile ? 20 :25} right={isMobile ? 126 :230}/>
        <CircleContainer width={isMobile ? "16px" :"26px"} height={isMobile ? "16px" :"26px"} color={"#5291DB"} top={isMobile ? 135 :170} right={isMobile ? 137 :210}/>
        <CircleContainer width={isMobile ? "9px" :"26px"} height={isMobile ? "9px" :"26px"} color={"#5291DB"} top={isMobile ? 72 :105} right={isMobile ? 100 :175}/>
        <CircleContainer width={isMobile ? "9px" :"26px"} height={isMobile ? "9px" :"26px"} color={"#5291DB"} top={40} right={isMobile ? 30 :100}/>
        <CircleContainer width={isMobile ? "9px" :"26px"} height={isMobile ? "9px" :"26px"} color={"#5291DB"} top={isMobile ? 82 :110} right={isMobile ? 15 :45}/>
    </div>
  )
}

export default ImageContainer1