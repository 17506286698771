import React from "react";
import HowCards from "./HowCards";
import { useMediaQuery } from "@mui/material";
import { NavLink } from "react-router-dom";

const HowItWork = () => {
  const isMobile = useMediaQuery("(max-width:724px)");
  return (
    <div className="" style={{ marginTop:"85px", paddingBottom:"185px"}}>
      <h2 style={{ fontSize: "40px", fontWeight: "600"}}>
        How It
        <span style={{ color: "#407BFF" }}> Works</span>
      </h2>
      <div className="flex justify-center" style={{ columnGap: "40px", flexDirection:isMobile&&"column"}}>
        <HowCards
          head={"Book Call"}
          data={"Fill the form to get your booking done"}
          image={"Images/guidanceSection/card1.png"}
        />
        <HowCards
          head={"1:1 video call"}
          data={"Get on a video call with our expert to have a 1:1 call."}
          image={"Images/guidanceSection/card2.png"}
        />
        <HowCards
          head={"Discuss Challenges"}
          data={
            "Discuss your pain points and get solution for them from our expert."
          }
          image={"Images/guidanceSection/card3.png"}
        />
      </div>
      <button
          style={{
            backgroundColor: "#407BFF",
            color: "white",
            width: "180px",
            height: "55px",
          }}
        >
          <NavLink
            to="https://calendly.com/bizowl "
            className="no-underline text-white hover:text-current active:text-current visited:text-current"
          >
            Book Call
          </NavLink>
        </button>
    </div>
  );
};

export default HowItWork;
