import React from "react";
import { useNavigate } from "react-router-dom";

const Heading = ({ name ,category}) => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex justify-between my-20 md:mx-16">
        <div className="flex">
          <hr className="m-2  border-t-8 border-blue-600 my-4 w-6" />
          <h1 className="text-black font-semibold md:font-bold text-2xl md:text-4xl ">
            {name}
          </h1>
        </div>
        <button className="hidden xl:block bg-blue-500 text-white px-4 rounded-xl md:w-36 md:h-11 text-lg" onClick={()=>navigate(`/resources/${category}`)}>
          See All
        </button>
      </div>
    </div>
  );
};

export default Heading;
