import React, { useEffect, useState } from "react";
import videoImg from "./assets/videoImg.png";

import { AiOutlineClose } from "react-icons/ai";

const VideoCard = ({h1,h3,videoId}) => {

   const [isIframeLoaded, setIframeLoaded] = useState(false);

  const handleLoadIframe = () => {
    setIframeLoaded(true);
  };
  const handleCloseIframe = () => {
    setIframeLoaded(false); // Return to the thumbnail when the background is clicked
  };

  useEffect(() => {
    if (isIframeLoaded) {
      document.body.style.overflow = "hidden"; // Disable scroll when iframe is open
    } else {
      document.body.style.overflow = ""; // Enable scroll again when iframe is closed
    }

    return () => {
      document.body.style.overflow = ""; // Cleanup on component unmount
    };
  }, [isIframeLoaded]);
  
  return (
    <div className="">
    <div className="my-4 bg-white rounded-3xl w-[300px] h-[461px] shadow-[0_0_10px_0_rgba(0,0,0,0.3)] ">
      <div className="w-[100%] h-[298px] ">
        {!isIframeLoaded ? (
          <div
            onClick={handleLoadIframe}
            style={{ cursor: "pointer", position: "relative", }}
          >
            <img
              src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
              alt="YouTube thumbnail"
              style={{ width: "100%", height: "auto", borderRadius:"25px 25px 0px 0px"}}
            />
          </div>
        ) : (
          <>
            {/* Fullscreen overlay with iframe centered */}
            <div
              className="fixed inset-0 bg-[#191919] bg-opacity-90 flex justify-center items-center z-50"
              onClick={handleCloseIframe} // Clicking the background returns to thumbnail
            >
            <button
      onClick={handleCloseIframe} // Close the iframe on clicking the cross button
      style={{ position: "absolute", top: "10px", right: "10px", }}
      className="text-white text-2xl bg-transparent border-none cursor-pointer scale-100 hover:scale-150"
    >
      <AiOutlineClose />
    </button>
              <div
                onClick={(e) => e.stopPropagation()} // Prevent closing when clicking the iframe
                style={{ width: "80%", maxWidth: "800px"}}
              >
              
                <iframe
                  width="100%"
                  height="450"
                  src={`https://www.youtube.com/embed/${videoId}?autoplay=1`} // Autoplay when clicked
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </>
        )}
      </div>
      <h1 className="pt-3 pl-5 text-[30px] font-semibold">{h1}</h1>
      <h3 className="pb-3 pl-5 font-semibold text-[25px] text-gray-500">
        {h3}
      </h3>
    </div>
  </div>
  );
};

export default VideoCard;
