import React from 'react'
import CircleContainer from './CircleContainer'
import { useMediaQuery } from '@mui/material';

const ImageContainer2 = () => {
  
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div className='absolute' style={{borderRadius: "35px", backgroundColor : "#ddbed1", width: isMobile ? "159px" :"290px", height:isMobile ? "186px" :"342px", 
    top:isMobile ? "532px" : isTab ? "556px" :isLaptop ? "237px" : "160px", 
    right:isMobile ? "32px" : isTab ? "55px" :isLaptop ? "7px" : "30px"}}>
    <img className='absolute bottom-0' style={{right:isMobile? "-20px" : "-35px", zIndex:10}} src="Images/guidanceSection/young-handsome.png" alt="" />
    <img className='absolute bottom-0' style={{right:"20px", zIndex:1, height:isMobile && "182px"}} src="Images/guidanceSection/Vector89.png" alt="" />
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} top={isMobile ? 20 :60} right={isMobile ? 116 :245}/>
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} top={isMobile ? 72 :150} right={isMobile ? 97 :235}/>
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} top={isMobile ? 88 :120} right={isMobile ? 128 :175}/>
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} top={isMobile ? 53 :30} right={isMobile ? 139 :185}/>
    <CircleContainer width={isMobile ? "10px" :"16px"} height={isMobile ? "10px" :"16px"} color={"#dab6cc"} top={isMobile ? 160 :200} right={isMobile ? 137 :30}/>
</div>
  )
}

export default ImageContainer2