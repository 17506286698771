import React from "react";
import ImageContainer1 from "./ImageContainer1";
import ImageContainer2 from "./ImageContainer2";
import ImageContainer3 from "./ImageContainer3";
import ImageContainer4 from "./ImageContainer4";
import { useMediaQuery } from "@mui/material";
import { NavLink, useNavigate } from "react-router-dom";

const GuidanceHeroSection = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  const islg = useMediaQuery("(max-width:1339px)");
  return (
    <div
      className="flex relative"
      style={{ 
        marginTop:isLaptop ? "20px" :"130px", 
        // marginBottom:isTab ? "550px" : isLaptop ? "250px" : "0px", 
        marginLeft: !isMobile ? "50px" : isTab && "22px", 
      fontFamily: "'Cabin'", width: isMobile && "100%" }}
    >
      <div
        className="bookCallSection w-6/12 flex flex-col"
        style={{ alignItems: "start", paddingTop: isMobile && "50px" }}
      >
        <h2
          className="text-[#263238]"
          style={{
            fontSize: isMobile
              ? "25px"
              : isTab
              ? "30px"
              : isLaptop
              ? "30px"
              : islg
              ? "30px"
              : "30px",
              fontWeight: "600"
          }}
        >
          Get Access to
          <span className="text-[#1C6ED0]"> Extra Brains</span>
        </h2>
        <div className="flex" style={{ marginTop: "8px" }}>
          <h2
            className="text-[#263238]"
            style={{
              fontSize: isMobile
                ? "25px"
                : isTab
                ? "30px"
                : isLaptop
                ? "30px"
                : "30px",
                fontWeight: "600"
            }}
          >
            For Your <span className="text-[#1C6ED0]">Startup</span>
          </h2>
          <div
            className="relative"
            style={{
              backgroundColor: "#1C6ED0",
              width: isMobile
                ? "20px"
                : isTab
                ? "30px"
                : isLaptop
                ? "30px"
                : "40px",
              height: isMobile
                ? "20px"
                : isTab
                ? "25px"
                : isLaptop
                ? "30px"
                : "40px",
              borderRadius: "50%",
              left: isMobile? "12px": "20px",
            }}
          ></div>
          <div
            style={{
              backgroundColor: "black",
              width: isMobile
                ? "20px"
                : isTab
                ? "30px"
                : isLaptop
                ? "30px"
                : "40px",
              height: isMobile
                ? "20px"
                : isTab
                ? "30px"
                : isLaptop
                ? "30px"
                : "40px",
              borderRadius: "50%",
            }}
          ></div>
        </div>
        <p
          className="text-[20px] mt-6"
          style={{
            fontSize: isMobile
              ? "12px"
              : isTab
              ? "14px"
              : isLaptop
              ? "14px"
              : "14px",
            marginTop: "24px",
          }}
        >
          Speak with experts to get guidance for
        </p>
        <p
          className="text-[20px] mb-6"
          style={{
            fontSize: isMobile
              ? "12px"
              : isTab
              ? "14px"
              : isLaptop
              ? "14px"
              : "14px",
            marginBottom: "24px",
          }}
        >
          you business. Get support for any stage of business.
        </p>
        <button
          className="bg-[#1C6ED0] text-white font-semibold text-[22px]"
          style={{ border: "1px solid black" }}
        
        >
        <NavLink to="https://calendly.com/bizowl " className="no-underline text-white hover:text-current active:text-current visited:text-current">Book Call</NavLink>
       
        </button>
        <h2
          className="text-[20px] font-semibold mt-12 relative top-2"
          style={{ fontSize: "20px", fontWeight: "bold", marginTop: "48px" }}
        >
          Trusted By
        </h2>
        <p className="text-[#1C6ED0] text-[50px]" style={{ fontSize: "50px" }}>
          5K+
        </p>
        <p
          className="font-semibold text-[15px] relative top-[-20px]"
          style={{ fontSize: "15px", top: "-20px", fontWeight: "600" }}
        >
          People
        </p>
      </div>
      <div className="photo">
        <ImageContainer1 />
        <ImageContainer2 />
        <ImageContainer3 />
        <ImageContainer4 />
      </div>
    </div>
  );
};

export default GuidanceHeroSection;
