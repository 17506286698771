import { useMediaQuery } from "@mui/material";
import React from "react";

const ServicePageHeroSection = () => {
  const isMobile = useMediaQuery("(max-width:547px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1286px)");
  return (
    <div
      className="flex h-auto w-full justify-around"
      style={{
        marginBottom: "100px",
        flexDirection: (isTab || isMobile) && "column",
        marginTop: (isTab || isMobile) && "50px",
        gap: "10px",
      }}
    >
      {/* Left Section */}
      <div className="flex flex-col relative" style={{ width: isMobile ? "" : "450px",alignItems:"start",marginBottom:isTab&&"0px"||"64px",marginTop:isTab&&"60px"||"0px"}}>
        <div
          className="flex"
          style={{
            marginBottom: isMobile ? "5px" : "24px",
            justifyContent: isMobile && "center",
            alignItems:"start",
            gap: isMobile? "6px" : "10px",
          }}
        >
          <img src="/Images/servicePage/heroSection/emoji.png" alt="" style={{width:isMobile? "20px" : "30px"}}/>
          <h3
            className="text-[23px] font-bold text-[#263238] w-[85%]"
            style={{
              textAlign: "start",
              fontSize: isMobile ? "12px" : "20px",
              lineHeight: isMobile && "1.5",
            }}
          >
            GET ACCESS TO ALL SERVICES YOU NEED TO
            <span style={{ color: "#1C6ED0" }}> START & GROW</span>
          </h3>
        </div>
        <h3
          className="font-semibold text-[#263238]"
          style={{
            fontSize: isMobile ? "24px" : "45px",
            textAlign: "start",
            marginBottom: isMobile ? "10px" : "24px",
            fontWeight: "550"
          }}
        >
          Business Services From
          <span style={{ color: "#1C6ED0" }}> Idea Stage to Growth</span>
        </h3>
        <h4
          className=" text-[##919a9c] w-[90%]"
          style={{
            color: "#919a9c",
            textAlign: "start",
            fontSize: isMobile ? "12px" : "20px",
          }}
        >
          Get all services in once place that you require to start and grow your
          business.{" "}
        </h4>
      </div>

      {/* Right Section */}
      <div style={{marginTop:isTab&&"0px"||"16px"}}>
        {isMobile ? <img src="/Images/servicePage/heroSection/banner1.png" alt="banner1"/> : <img
          src="/Images/servicePage/heroSection/banner1.png"
          alt="banner1"
          style={{
            width: isMobile ? "370px" : isLaptop ? "576px" : "676px",
            height: isMobile ? "410px" : isLaptop ? "517px" : "600px",
          }}
        />}
        
      </div>
    </div>
  );
};

export default ServicePageHeroSection;
