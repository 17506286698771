import React from 'react'
import CircleContainer from './CircleContainer'
import { useMediaQuery } from '@mui/material';

const ImageContainer3 = () => {
  
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div className='absolute' style={{borderRadius: "25px", backgroundColor : "#fcd982", 
      width:isMobile ? "140px" :"235px", 
      height:isMobile ? "88px" :"163px", 
    top:isMobile ? "412px" : isTab ? "375px" :isLaptop ? "50px" :"-42px", 
    right:isMobile ? "50px" : isTab ? "95px" :isLaptop ? "50px" :"72px"}}>
        <img className='absolute bottom-0' style={{right:isMobile ? "100px":"160px",top:isMobile ? "2px":"10px", zIndex:1, width:isMobile&& "40px", height:isMobile&& "40px"}} src="Images/guidanceSection/hugeicons_mentor.png" alt="" />
        <img className='absolute bottom-0' style={{right:isMobile ? "5px":"5px",top:isMobile ? "52px":"105px", zIndex:1, width:isMobile&& "40px", height:isMobile&& "40px"}} src="Images/guidanceSection/Ellipse766.png" alt="" />
        <img className='absolute bottom-0' style={{right:isMobile ? "10px":"19px",top:isMobile ? "55px":"118px", zIndex:1, width:isMobile&& "28px", height:isMobile&& "28px"}} src="Images/guidanceSection/tabler_arrow-up.png" alt="" />
        <p className='text-[13px] font-medium absolute text-[#292929]' style={{fontSize: !isMobile && "20px", top: isMobile ? "10px" : "20px", right:isMobile ? "0px" :"42px", left:isMobile ? "25px" :"60px"}}>Talk to Experts</p>
    <CircleContainer width={isMobile ? "16px" :"26px"} height={isMobile ? "16px" :"26px"} color={"#fdd36b"} top={isMobile ? 55:90} right={isMobile ? 108:185}/>
    <CircleContainer width={isMobile ? "9px":"15px"} height={isMobile ? "9px":"15px"} color={"#fdd36b"} top={isMobile ? 75:130} right={isMobile ? 95:160}/>
</div>
  )
}

export default ImageContainer3