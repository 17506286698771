import { useMediaQuery } from "@mui/material";
import React from "react";

const HowCards = ({ head, data, image }) => {
  const isMobile = useMediaQuery("(max-width:724px)");
  return (
    <div
      className="bg-white p-4 relative flex flex-col justify-around"
      style={{
        borderRadius: "15px",
        width: "329px",
        height: "419px",
        marginTop: "30px",
        marginBottom: "50px",
        transition: "transform 0.2s ease-in-out",
        boxShadow: isMobile
          ? "0px 2px 4px rgba(0, 0, 0, 0.1)"
          : "0px 4px 8px rgba(0, 0, 0, 0.2)",
      }}
      onMouseOver={(e) => (e.target.style.transform = "scale(1.05)")}
      onMouseOut={(e) => (e.target.style.transform = "scale(1)")}
    >
      <h2 style={{ fontSize: "28px", fontWeight: "500" }}>{head}</h2>
      <p style={{ color: "#a3a8ab", fontSize: "14px", marginTop: "10px" }}>
        {data}
      </p>
      <img style={{ marginTop: "30px" }} src={image} alt={head} />
    </div>
  );
};

export default HowCards;
