import {useEffect, useState} from 'react';
import Pm_summary from '../../Components/getStarted/pm_summary'
import Rating_box from '../../Components/getStarted/rating_box'
import All_features from '../../Components/getStarted/all_features';
import bizowl_logo from '../../Components/getStarted/assets/bizowl_logo.png'
import './getStartedPage.css'
import call_logo from '../../Components/getStarted/assets/phone-icon.png'
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { partnerDB } from "../../config/firebase";
import { useSearchParams } from 'react-router-dom';
import useServicesStore from './storePr';

function App() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
const index = searchParams.get('index');
  const [isLoading, setIsLoading] = useState(true); // New loading state
  const {servicesData, setServicesData} = useServicesStore();
  const [state,setState]=useState("About");

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;

      setIsLoading(true); // Set loading state to true before fetching

      try {
        const q = query(
          collection(partnerDB, "newPrData"),
          where('id', '==', Number(id))
        );

        const querySnapshot = await getDocs(q);
        const filteredPartners = querySnapshot.docs.map((doc) => doc.data());

        console.log("filteredPartners data", filteredPartners)
const matchingPartner= filteredPartners.map((partner)=>{
  console.log(partner)
  const matchingPackages= Object.entries(partner.packages).filter(([pkgKey, pkgData])=>{
    
    const replacedPacakgeName  =pkgData.name.replace(/\+/g, " ");
    return replacedPacakgeName ===index

  })
 
  
 // Return the partner with only the matching packages
 return {
  ...partner,
  packages: Object.fromEntries(matchingPackages) // Convert back to object form
};
}).filter(partner => Object.keys(partner.packages).length > 0);

        console.log(matchingPartner)
        setServicesData(matchingPartner);
        console.log("services data",servicesData)

        setIsLoading(false); // Set loading state to false after fetching
      } catch (error) {
        console.error("Error fetching data: ", error);
        setIsLoading(false); // Ensure loading state is reset on error
      }
    };

    fetchData();
  }, [id, partnerDB]);

  // Conditional rendering based on loading state and servicesData
  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!servicesData || servicesData.length === 0) {
    return <p>No service data found.</p>;
  }

  console.error("service data: ", servicesData);
  const displayName = servicesData[0]?.name.substring(0, 3).toUpperCase();
  console.log()
  
 
  const middleIndex = Math.floor(displayName.length / 2);

  const firstPart = displayName.slice(0, middleIndex);
  const middleLetter = displayName[middleIndex];
  const lastPart = displayName.slice(middleIndex + 1);

  return (
   
      <div className='app'>
        <nav>
          <div id='logo'>
              <img id='logo-text' src={bizowl_logo} />
          </div>

          <div id='need-help'>
          <div className='call-logo'><img src={call_logo}/></div><p>Need Help?</p>
          </div>
        </nav>

        <div className="content-About">
        <div className='section1'>
          <div id='logo-box'>
              <div id='logo-item'>
                <p>{firstPart}<span id='X'>{middleLetter}</span>{lastPart}</p>
              </div>
          </div>
          <Rating_box index={index} firstPart={firstPart} middleLetter={middleLetter} lastPart={lastPart}/>
        </div>
        <div className="section2">
            <Pm_summary  firstPart={firstPart} middleLetter={middleLetter} lastPart={lastPart} index={index}/>
        </div>
        <div className="section3-About">

         <All_features index={index}/>

        </div>
        </div>
      </div>
  )
}

export default App
