import React from "react";
import Herosection from "./components/Herosection";
import Heading from "./components/Heading";
import Card from "./components/Card";
import leftAr from "./components/assets/leftAr.png";
import rightAr from "./components/assets/rightAr.png";
import blogImg from "./components/assets/blogImg.png";
import marketImg from "./components/assets/marketImg.png";
import VideoCard from "./components/VideoCard";
import { Helmet } from "react-helmet";
import NavBar from "../Header/NavBar";
import Footer from "../Footer/Footer";

const dataForMRCard=[
  {
    id:"pet-care-sector",
    category:"Public Relations",
    heading:"A complete Overview on Petcare industry",
    description:"In today's digital age, getting your message out to the right customer segment or audience is crucial for businesses of all sizes. .....",
    btn:"View",
    date:"14-Sep-2024"
    

  }
]

const blogPosts = [
  {
    id: 1,
    icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1697129744/Bizowl/blog1_mjudxr.jpg",
    jobTitle: "Business Service",
    tag: "business-service",
    urlTitle: "the-importance-of-an-end-to-end-business-service-aggregating-platform",
    title:
      "The Importance of an End-to-End Business Service Aggregating Platform",
    description:
      "In today's fast-paced business world, small business owners and startup entrepreneurs ...",
    date: "12-Oct-2023",
  },
  {
    id: 2,
    icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1721878655/bizowl-blog-2_di2op8.png",
    jobTitle: "Public Relations",
    tag: "pr",
    urlTitle: "21-benefits-of-press-release-distribution",
    title:
      "21 Benefits of Press Release Distribution: Elevate Your Brand's Visibility and Credibility",
    description:
      "Discover 21 powerful benefits of press release distribution that can elevate your brand's .....",
    date: "24-Jul-2024",
  },
  {
    id: 3,
    icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1723669728/bizowl-blog-3_vwmmib.png",
    jobTitle: "Public Relations",
    tag: "pr",
    urlTitle: "best-press-release-distribution-services",
    title:
      "Finding the Best Press Release Distribution Services: A Comprehensive Guide",
    description:
      "Discover the best press release distribution services to boost your media coverage and....",
    date: "15-Aug-2024",
  },
  {
    id: 4,
    icon: "https://res.cloudinary.com/dwjzihwuq/image/upload/v1724100311/photo1_yntb2p.png",
    jobTitle: "Public Relations",
    tag: "pr",
    urlTitle: "top-benefits-of-using-an-aggregator-for-online-press-release-distribution",
    title:
      "Top Benefits of Using an Aggregator for Online Press Release Distribution",
    description:
      "Discover how using an aggregator for online press release distribution can streamline ...",
    date: "20-Aug-2024",
  },
  // Add more blog posts here
];

const Resources = () => {
  const VideoCompData =[
   
      {h1:"After PMF : People Customer Sucess",h3:" Marketing",videoId:"dpyGKtkYubY"},
      {h1:"After PMF : People Customer Sucess",h3:"Marketing",videoId:"gEiK-bDOADg"},
    {h1:"After PMF : People Customer Sucess",h3:"Marketing",videoId:"dpyGKtkYubY"},
   
  ]
  return (
    <div >
    <Helmet>
    <meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<title>Business Resources Hub | Guides, Templates, Blogs & Market Research</title>
<meta name="description" content="Explore Bizowl's comprehensive business resources including guides, templates, blogs, eBooks, market research, videos, and success stories to help you grow your business."/>
<meta name="keywords" content="business resources, guides, templates, blogs, market research, eBooks, success stories, case studies, business knowledge base, learning resources, business guides"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://bizzowl.com/resources "/>

<meta property="og:title" content="Business Resources Hub | Guides, Templates, Blogs & Market Research"/>

<meta property="og:description" content="Unlock Bizowl's extensive resources to solve your business challenges. Access blogs, guides, templates, eBooks, market research, and more for your business growth."/>

<meta
      property="og:image"
      content="https://bizzowl.com/Images/logos/resources.png"
    />
<meta property="og:url" content="Bizowl - Business Services: From Idea to Growth"/>

<meta name="twitter:card" content="summary_large_image"/>

<meta name="twitter:title" content="Business Resources Hub | Guides, Templates, Blogs & Market Research"/>

<meta name="twitter:description" content="Access a wide range of business resources like blogs, guides, templates, eBooks, and success stories to help you navigate business challenges."/>

<meta name="twitter:image" content="Bizowl - Business Services: From Idea to Growth"/>

    </Helmet>
    <NavBar/>
      {/* <Header /> */}
      <div className="bg-gradient-to-r from-blue-100 text-left">
        {/* <div>Resources Section</div> */}
        <Herosection />
        <Heading name={"Blogs"} category="blogs"/>
        <div className=" w-full flex flex-wrap justify-around">
          <Card
            img={blogPosts[3].icon}
            heading={
             blogPosts[3].description
            }
            time={blogPosts[3].date}
            category={blogPosts[3].jobTitle}
            btn={"Read More"}
             type="blogs"
             tag={blogPosts[3].tag}
             urlTitle={blogPosts[3].urlTitle}
          />
          <Card
            img={blogPosts[2].icon}
            heading={
             blogPosts[2].description
            }
            time={blogPosts[2].date}
            category={blogPosts[2].jobTitle}
            btn={"Read More"}
             type="blogs"
             tag={blogPosts[2].tag}
             urlTitle={blogPosts[2].urlTitle}
          />
          <Card
            img={blogPosts[1].icon}
            heading={
             blogPosts[1].description
            }
            time={blogPosts[1].date}
            category={blogPosts[1].jobTitle}
            btn={"Read More"}
             type="blogs"
             tag={blogPosts[1].tag}
             urlTitle={blogPosts[1].urlTitle}
          />

<Card
            img={blogPosts[0].icon}
            heading={
             blogPosts[0].description
            }
            time={blogPosts[0].date}
            category={blogPosts[0].jobTitle}
            btn={"Read More"}
             type="blogs"
             tag={blogPosts[0].tag}
             urlTitle={blogPosts[0].urlTitle}
          />
        
        </div>
        <div className="hidden xl:block">
          <div className="my-8  flex justify-evenly">
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={rightAr} alt="img" />
            </button>
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={leftAr} alt="img" />
            </button>
          </div>
        </div>
        <Heading name={"Market Research"} category="market-research"/>
        <div className="md:mx-16 flex justify-around lg:justify-start">
          <Card
            img={marketImg}
            id={dataForMRCard[0].id}
            heading={dataForMRCard[0].heading}
            description={dataForMRCard[0].description}
            category={dataForMRCard[0].category}
            btn={"View"}
            type="pdf"
            time={dataForMRCard[0].date}

          />
        </div>
        <Heading name={"Videos"} category="video-library"/>
        <div className="md:mx-8 flex flex-wrap gap-8 justify-center ">
          <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3} videoId={VideoCompData[0].videoId}/>
          <VideoCard h1={VideoCompData[1].h1} h3={VideoCompData[1].h3} videoId={VideoCompData[1].videoId}/>
          <VideoCard h1={VideoCompData[2].h1} h3={VideoCompData[2].h3} videoId={VideoCompData[2].videoId}/>
          <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3}/>
          <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3}/>
          <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3}/>
        </div>
        <div className="hidden xl:block">
          <div className="my-8 pb-[300px] flex justify-evenly">
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={rightAr} alt="img" />
            </button>
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={leftAr} alt="img" />
            </button>
          </div>
        </div>
        <Footer/>
      </div>
    
    </div>
  );
};

export default Resources;
