import './rating_box.css'
import message_icon from './assets/Vector-3.png'
import call_icon from './assets/Vector-1.png'
import video_icon from './assets/Vector.png'
import Rating from '@mui/material/Rating';



const Rating_box=({servicesData, firstPart,middleLetter, lastPart})=>{
    return(
        <div className="rating-container">

            <div id='heading'><p>{firstPart}<span id='x'>{middleLetter}</span>{lastPart}</p></div> 
             <div className='rating-items' id='ratings'><p>Ratings</p><div id='stars'><Rating /></div></div>
            <div className='rating-items-clickable' style={{marginTop:"3rem",
            marginBottom:"3rem"}}>
             {/*<div className='rating-item'><div className='icons'><img className='icon' src={video_icon} /></div><p>Video</p></div> */}
          
            </div>
        </div>
    )
}

export default Rating_box;