import React from "react";
import clockImg from "./assets/clock.png";
import { useNavigate } from "react-router-dom";

const Card = ({ img,btn,heading,
  description,category,id ,type,time,tag,urlTitle}) => {
    const navigate = useNavigate();
  return (
    <div>
      <div>
        <div class="my-4 w-[300px] h-[436px] p-[25px]  rounded-3xl  bg-white shadow-[0_0_10px_0_rgba(0,0,0,0.3)]">
          <div class="w-[98%] h-[160px]  border-black rounded-3xl">
            <img
              class="max-w-full max-h-full bg-contain"
              src={img}
              alt="blogImg"
            />
          </div>
          <div class="flex my-2">
            <button class="w-[84px] h-[35px]  font-bold text-[9px] text-center bg-blue-200 text-blue-700 rounded-lg">
              {category}
            </button>
            <img
              class="inline m-1 w-[16px] h-[16px] ml-3"
              src={clockImg}
              alt="clockImg"
            />
            <span class="text-gray-500 text-[10px] pt-1"> {time}</span>
          </div>
          <h1 class="text-xl font-bold">{heading}</h1>
          <p class="mt-2 text-[12px] font-medium text-left">
            {description}
          </p>
          <button class=" text-center bg-blue-500 text-white h-9 p-1 md:p-0 md:h-10 w-32 block mx-auto mt-4 rounded-md hover:bg-blue-700 transition-all duration-600"
          onClick={type==="pdf"?()=>navigate(`/resources/market-research?id=${id}`):()=>navigate(`/resources/blogs/${tag}/${urlTitle}`)}>
            {btn}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
