import { useMediaQuery } from '@mui/material';
import React from 'react'
import { NavLink } from 'react-router-dom'
import ServicePageHeroSection from '../../Components/servicePage/ServicePageHeroSection';
import ServicePageExperienceSection from '../../Components/servicePage/ServicePageExperienceSection';
import ServicePageExplore from '../../Components/servicePage/ServicePageExplore';
import ServicePageFeatures from '../../Components/servicePage/ServicePageFeatures';
import ServiceOageOurService from '../../Components/servicePage/ServiceOageOurService';
import { Helmet } from 'react-helmet';
import NavBar from '../../Components/Header/NavBar';
import { Navbar } from 'react-bootstrap';
import Footer from '../../Components/Footer/Footer';

const ServicePage = () => {
    const isMobile = useMediaQuery("(max-width:724px)");
  return (
   <div>
   <NavBar/>

  
    <div className="max-w-[95rem] mx-auto px-6 pt-[0px] font-cabin" style={{paddingLeft:isMobile?"5px":"0px",paddingRight:isMobile?"5px":"0px"}}>
 
    <Helmet>
    <meta charset="UTF-8"/>
<meta name="viewport" content="width=device-width, initial-scale=1.0"/>
<title>Expert Business Services: Idea Validation, Marketing, Web Development | Bizowl</title>
<meta name="description" content="Get expert business support from Bizowl. We offer services in idea validation, business modelling, product development, marketing, web design, SEO, and more to help your startup grow."/>
<meta name="keywords" content="Business Services, Idea Validation, Business Consultation, Business Modelling, Product Development, MVP, Marketing Services, Web Development, Design Services, SEO, Email Marketing, Social Media Marketing, Influencer Marketing, Market Research"/>
<meta name="robots" content="index, follow"/>
<meta name="author" content="Bizowl"/>
<meta http-equiv="Content-Language" content="en"/>
<link rel="canonical" href="https://bizzowl.com/services " />
<meta property="og:title" content="Expert Business Services: Idea Validation, Marketing, Web Development | Bizowl"/>
<meta property="og:description" content="Bizowl offers tailored business services to help your startup grow, from idea validation and product development to digital marketing and web design."/>
<meta property="og:image" content="https://bizzowl.com/path-to-image.jpg "/>
<meta property="og:url" content="https://bizzowl.com/services "/>
<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:title" content="Expert Business Services: Idea Validation, Marketing, Web Development | Bizowl"/>
<meta name="twitter:description" content="Grow your business with expert services from Bizowl, including idea validation, business modeling, product development, and marketing."/>
<meta name="twitter:image" content="https://bizzowl.com/path-to-image.jpg "/>

<script type="application/ld+json">
{
  `{
  @context ": "https://schema.org ",
@type ": "ProfessionalService",
"name": "Bizowl Business Services",
"url": "https://bizzowl.com/services ",
"logo": "https://bizzowl.com/path-to-logo.jpg ",
"image": "https://bizzowl.com/path-to-image.jpg ",
"description": "Bizowl offers expert business services including idea validation, business modeling, market research, product development, web development, marketing, branding, and more to help startups grow successfully.",
"telephone": "+91-935465143",
"address": {
@type ": "PostalAddress",
"streetAddress": "Dwarka",
"addressLocality": "New Delhi",
"addressRegion": "Delhi",
"postalCode": "110077",
"addressCountry": "IN"
},
"areaServed": {
@type ": "Place",
"name": "India"
},
"sameAs": [
"https://twitter.com/bizowlofficial ",
"https://instagram.com/bizowlofficial ",
"https://facebook.com/bizowl ",
"https://linkedin.com/company/bizowl"
],
"service": [
{
@type ": "Service",
"serviceType": "Idea Validation",
"description": "Expert validation to ensure your business idea is market-ready."
},
{
@type ": "Service",
"serviceType": "Business Modeling Consultation",
"description": "Develop a solid business model tailored to your startup’s vision."
},
{
@type ": "Service",
"serviceType": "Pricing Model",
"description": "Crafting pricing models for profitability and market competitiveness."
},
{
@type ": "Service",
"serviceType": "Revenue Model",
"description": "Designing sustainable revenue models to fuel business growth."
},
{
@type ": "Service",
"serviceType": "SWOT Analysis",
"description": "Detailed SWOT analysis for informed business decision-making."
},
{
@type ": "Service",
"serviceType": "Profitability Analysis",
"description": "Maximizing business profitability through tailored analysis."
},
{
@type ": "Service",
"serviceType": "Business Canvas Model",
"description": "Building effective business canvas models for startup success."
},
{
@type ": "Service",
"serviceType": "Market Entry Strategy",
"description": "Guidance on the best strategies for entering new markets."
},
{
@type ": "Service",
"serviceType": "Branding Proposition",
"description": "Helping businesses define strong branding propositions for market positioning."
},
{
@type ": "Service",
"serviceType": "Business Feasibility",
"description": "Determining the feasibility of business ideas through market and financial analysis."
},
{
@type ": "Service",
"serviceType": "Market Research",
"description": "Comprehensive market research services including market analysis, target audience analysis, competitor analysis, and trend analysis."
},
{
@type ": "Service",
"serviceType": "Product Roadmap",
"description": "Developing product roadmaps to guide your business strategy."
},
{
@type ": "Service",
"serviceType": "MVP Development",
"description": "Developing Minimum Viable Products (MVP) for market validation."
},
{
@type ": "Service",
"serviceType": "Web Development",
"description": "Custom web development services including Shopify development, website maintenance, and e-commerce development."
},
{
@type ": "Service",
"serviceType": "Mobile App Development",
"description": "Expert development services for mobile applications."
},
{
@type ": "Service",
"serviceType": "Marketing Services",
"description": "Comprehensive marketing services including SEO, email marketing, social media marketing, influencer marketing, and video marketing."
},
{
@type ": "Service",
"serviceType": "Go-To-Market Strategy",
"description": "Strategic go-to-market planning for successful product launches."
},
{
@type ": "Service",
"serviceType": "Design Services",
"description": "Professional design services including logo design, UI/UX design, and social creatives."
},
{
@type ": "Service",
"serviceType": "Advertising Services",
"description": "Targeted advertising services across platforms like PPC, Meta, LinkedIn, Twitter, and radio."
},
{
@type ": "Service",
"serviceType": "PR Strategy and Outreach",
"description": "Comprehensive PR services including press release writing, PR strategy, and media outreach."
}
],
"openingHours": [
"Mo-Fr 10:00-20:00",
"Sa-Su 10:00-18:00"
],
"priceRange": "$$"

  }`
}
</script>
    </Helmet>
        <div>
        {/* <NavLink to="/">
          <img src="/Images/logo.png" alt="logo" className="w-[200px] py-2"/>
        </NavLink> */}
        
        <ServicePageHeroSection/>
        <ServicePageExperienceSection/>
        <ServiceOageOurService/>
        <ServicePageFeatures/>
        <ServicePageExplore/>
        </div>
    </div>
    <Footer/>
    </div>
  )
}

export default ServicePage  