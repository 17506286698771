import arrow from "./assets/arrow1.png";
import blog1 from "./assets/blog1.png";
import eBook from "./assets/notebook.png";
import tag from "./assets/tag.png";
import ipad from "./assets/ipad.png";
import startup from "./assets/startup.png";
import media from "./assets/media1.png";
import lense from "./assets/lense.png";
import shade from "./assets/shade.png";
import smart from "./assets/Smart.png";
import vector from "./assets/Vector.png";

const Herosection = () => {
  return (
    <div>
      <div className="font-cabin flex flex-wrap lg:flex-nowrap p-[0.5rem] md:px-20 md:py-10">
        {/* ################################### Search div ############################################ */}

        <div className="ml-3 mt-14 md:mt-6 w-full lg:w-[592px] ">
          <h1 className="text-xl md:text-4xl font-bold">
            Master the Art of{" "}
            <span className="text-blue-600">
              Entrepreneurship
              <img
                src={arrow}
                alt="arrow"
                className="w-6 h-6 md:w-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl"
              />
              Solution to Every
            </span>
            &nbsp; Business Challenge
          </h1>
          <p className="my-5 md:text-xl md:font-semibold">
            Drive into our extensive library of tutorials, eBooks and podcasts
            covering business mangement, marketing finance and more.
          </p>
          <div className="bg-white h-14 md:h-[82px] mt-3 flex justify-center items-center rounded-lg">
            <input
              className="w-full h-full  focus:outline-none p-7 text-lg rounded-lg"
              type="text"
              placeholder="Search for Services"
            />
            <button className="bg-blue-500 text-white  md:h-12 w-36 m-3 rounded-md hover:bg-blue-700 transition-all duration-600">
              Search
            </button>
          </div>
          <div className="mt-3 text-sm md:text-lg">
            <p className="m-2 text-[#7897BA] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Branding
            </p>
            <p className="m-2 text-[#FFC741] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Business Fundamentals
            </p>
            <p className="m-2 text-[#CF9DBB] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Marketing
            </p>
            <p className="m-2 text-[#A1D4C9] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Sales
            </p>
            <p className="m-2 text-[#CF9DBB] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Product
            </p>
            <p className="m-2 text-[#FFC741] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Market Research
            </p>
            <p className="m-2 text-[#7897BA] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Design
            </p>
            <p className="m-2 text-[#A1D4C9] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Operations
            </p>
            <p className="m-2 text-[#7897BA] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Finance
            </p>
            <p className="m-2 text-[#FFC741] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              GTM
            </p>
            <p className="m-2 text-[#CF9DBB] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              PMF
            </p>
            <p className="m-2 text-[#A1D4C9] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Business Model
            </p>
            <p className="m-2 text-[#FFC741] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Funding
            </p>
            <p className="m-2 text-[#FFC741] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Pricing
            </p>
            <p className="m-2 text-[#A1D4C9] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              MVP
            </p>
            <p className="m-2 text-[#CF9DBB] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Customer Analysis
            </p>
            <p className="m-2 text-[#7897BA] border border-[#455A64]  p-1 px-4 rounded-full bg-opacity-40 bg-white inline-block">
              Adverting
            </p>
          </div>
        </div>

        {/* ################################### Images  div ############################################ */}

        <div className="relative p-4 md:p-0 w-[100%] lg:w-1/2 md:ml-10 mt-8">
          {/* shaded circle img float over other Images */}
          <img
            className="hidden xl:block   max-w-56 h-80 absolute left-[13rem] top-[2rem] md:left-72 md:top-24 rotate--6 z-10"
            src={shade}
            alt="img"
          />
          {/* case Studies text float over Images */}
          <h4 className="absolute top-16 left-20 md:top-16 md:left-24 text-lg text-blue-600 font-semibold">
            Case Studies
          </h4>

          {/* below div contain two imgs */}
          <div>
            <img
              className="inline w-[63%] md:w-[20rem] md:h-[11rem]"
              src={ipad}
              alt="img"
            />
            <img
              className="inline w-[52px] h-[50px] ml-8 md:ml-16"
              src={eBook}
              alt="img"
            />
          </div>

          {/* below div contain two imgs */}
          <div className="flex">
            <div className="flex justify-center items-center w-[215px] md:h-[171px] bg-[#407BFF] rounded-full ">
              <img
                className="inline w-[50%] md:w-[85px] md:h-[85px]"
                src={tag}
                alt="img"
              />
            </div>
            <img
              className="inline w-[50%] md:h-[180px]"
              src={startup}
              alt="img"
            />
          </div>

          {/* below div contain two imgs */}
          <div className="flex relative">
            <div className="absolute left-28 md:left-40 -top-7">
              <img
                className="w-[49px] md:w-[90px] md:h-[75px]"
                src={vector}
                alt="img"
              />
              <img
                className="w-[45px] h-[42px] md:w-[62px] md:h-[62px] absolute top-1 left-2 md:top-2 md:left-3"
                src={smart}
                alt="img"
              />
            </div>
            {/* below div contain three imgs */}
            <div className="flex w-full">
              <img className=" w-[30%] md:h-[228px]" src={lense} alt="img" />
              <div className="flex justify-center items-center border-2 border-blue-600 rounded-full w-[30%] md:h-[222px]">
                <img
                  className="w-[60%] md:w-[104px] md:h-[100px]"
                  src={blog1}
                  alt="img"
                />
              </div>
              <div className="flex justify-center items-center rounded-full w-[30%] md:h-[222px]  bg-[#FFC741]">
                <img
                  className="w-[60%] lg:w-[100px] lg:h-[100px]"
                  src={media}
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Herosection;
