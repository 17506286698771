import React from "react";
import CircleContainer from "./CircleContainer";
import { useMediaQuery } from "@mui/material";

const ImageContainer4 = () => {
  
  const isMobile = useMediaQuery("(max-width:724px)");
  const isTab = useMediaQuery("(max-width:1024px)");
  const isLaptop = useMediaQuery("(max-width:1224px)");
  return (
    <div
      className="absolute shadow-lg"
      style={{
        borderRadius: "20px",
        backgroundColor: "#F3F9FF",
        width: isMobile ? "140px" :"230px",
        height: isMobile ? "75px" :"135px",
        top: isMobile ? "714px" : isTab ? "860px" :isLaptop ? "565px" :"450px",
        right: isMobile ? "204px" : isTab ? "405px" :isLaptop ? "353px" :"380px",
      }}
    >
      <CircleContainer
        width={"9px"}
        height={"9px"}
        color={"#1C6ED0"}
        top={isMobile? 12:15}
        right={isMobile? 106:200}
      />
      <CircleContainer
        width={"4px"}
        height={"4px"}
        color={"#1C6ED0"}
        top={25}
        right={isMobile? 114:170}
      />
      <CircleContainer
        width={"4px"}
        height={"4px"}
        color={"#1C6ED0"}
        top={isMobile? 65:120}
        right={isMobile? 98:200}
      />
      <CircleContainer
        width={"2px"}
        height={"2px"}
        color={"#1C6ED0"}
        top={isMobile? 65:108}
        right={isMobile? 107:188}
      />
      <CircleContainer
        width={"9px"}
        height={"9px"}
        color={"#fbd883"}
        top={isMobile? 59:112}
        right={isMobile? 82:170}
      />
      <CircleContainer
        width={"5px"}
        height={"5px"}
        color={"#fbd883"}
        top={isMobile? 16:30}
        right={isMobile? 94:196}
      />
      <div className="flex justify-center p-2" style={{marginTop:"15px"}}>
        <img src="Images/guidanceSection/Image4.png" className="relative" style={{width:isMobile&&"50px", height:isMobile&&"44px" ,top:isMobile&&"-6px", right:isMobile&&"-3px"}} alt="" />
        <img className="relative" src="Images/guidanceSection/text.png" style={{width:isMobile?"95px":"180px", height:isMobile&&"45px", top:isMobile&&"-11px", right:isMobile&&"9px", padding: "2px"}} alt="" />
      </div>
    </div>
  );
};

export default ImageContainer4;
