// working on iut



import React, { useEffect, useState } from "react";

import ReactSlider from "react-slider";
import useFormData from "./util/useFormData";
//assets
import BizowalLogo from "./assets/logo-of-BIZOWL--Business-Services.png";
import SalesSupport from "./assets/Sales-Support 1.png"
import Call from "./assets/call.png"
import PhonePic from "./assets/PhoneIcon.png";
import MessageImg from "./assets/Vector (2).png";
import PhoneImg from "./assets/Vector (3).png";
import VideoImg from "./assets/Vector (4).png";
import Star from "./assets/solar_star-bold.png";
import Filter from "./assets/filter.png"
import DownArrowPic from "./assets/Vector (5).png";
import BlueDownArrow from "./assets/Vector (6).png";
import Line from "./assets/Line 29 (1).png";
import Growth from "./assets/BizowlPic/growth 1.png";
import Price from "./assets/BizowlPic/best-price 1.png";
import Support from "./assets/BizowlPic/service 1.png";
import Quality from "./assets/BizowlPic/quality-control 1.png";
import Compare from "./assets/BizowlPic/compare 1.png";
import Experience from "./assets/BizowlPic/customer-review 2.png";
import Image1 from "./assets/BizowlPic/6895878 1.png";
import PopupModal from "./util/Popup";
import FilterComponent from "./util/FilterComponent";
import { MdFilterAlt, MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdKeyboardArrowLeft } from "react-icons/md";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Menu,
  MenuItem,
  Typography,
  FormControl,
  FormControlLabel ,
  Radio ,
  useMediaQuery,
  makeStyles,
  styled,
} from "@mui/material";
import "./serviceStyle.scss";
import { Center } from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { partnerDB } from "../../config/firebase";
import zIndex from "@mui/material/styles/zIndex";
import CommonFilter from "./CommonFilter";




const StyledFormControl = styled(FormControl)({
  display: 'flex',
  flexDirection: 'column', // Arrange items in a row
  alignItems: 'start',
  color:"gray"
});

const StyledFormControlLabel = styled(FormControlLabel)({
  display: 'flex',
  flexDirection: 'row', // Arrange items in a row
  alignItems: 'center',
 // Adjust spacing between radio and label
 fontSize:"5px"
});

const ServicePR = () => {

  const [visibleItemId, setVisibleItemId] = useState(null);
  const [showMoreContent, setShowMoreContent] = useState(null);
  const { formData } = useFormData();
  const [ratings, setRatings] = useState(5);
  const [services, setServices] = useState(6);
  const [isChecked, setIsChecked] = useState({});
  const [comparisonTrayVisible, setComparisonTrayVisible] = useState(false);
  const [selectedServices, setSelectedServices] = useState([]);
  // const [partners, setPartners] = useState([]);
  const [beforeSortPartners, setBeforeSortPartners] = useState([]); //to sort he element to  default

  const [filteredPartners, setFilteredPartners] = useState([]);
  const [actualPartners, setActualPartners] = useState([]);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true); // New loading state
  // State to manage the anchor element for the menu

  // State to manage the selected index
  
  const[selectedIndex,setSelectedIndex]= useState(null);
 
  // const[selectedIndexSort,setSelectedIndexSort]= useState(null);
  const [selectedIndexPr, setSelectedIndexPr] = useState("");
  const [selectedIndexLang, setSelectedIndexLang] = useState("");
  const [selectedIndexGeo, setSelectedIndexGeo] = useState("");
  const [selectedIndexTarget, setSelectedIndexTarget] = useState("");
  const [selectedIndexIndustry, setSelectedIndexIndustry] = useState("");

  //for filters icon in mobile size
  const [showOverlay, setShowOverlay] = useState(false);
  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const [openMenu, setOpenMenu] = useState(null);

  const [isFiltering, setIsFiltering] = useState(true); // State to track if filters have been applied

  // data for filters section
  const industries = [
    'Technology',
    'E-commerce',
    'Fintech',
    'IOT',
    'Artificial Intelligence',
    'Food and Beverage',
    'Health & Wellness',
    'Media & Entertainment',
    'Travel & Hospitality',
    'Education',
    'Real Estate',
    'Energy',
    'Agriculture',
    'B2B SaaS',
    'BioTech',
    'Space Tech',
    'Cyber Security',
    'Legal Tech',
    'Fashion & Apparel',
    'Beauty & Personal Care',
    'Automotive',
    'Retail',
    'Cleantech',
    'Deeptech',
    'Robotics',
    'Power',
    'VR/AR',
    'Other'
  ];
  
  const options = [
    "Yes","No"
  ];
  const menuOptions = ["Default", "Price:High to Low", "Price:Low to High"];

  const languages = ["English","Hindi","Other"];

  const geoTargets= ["National", "International", "Local"]


const [filters, setFilters] = useState({
    priceRange:[formData.priceRange[0],formData.priceRange[1]],
    industry:formData.industry,
    language:formData.language,
    geoTarget:formData.targetAudience,
    outlets:1,
    geoTag:"",
    pressReleasing:formData.pressReleaseReady,


});

console.log("showing form data:::",formData)

  const handleFilter = (event) => {
    const {name, value} =event.target;
    setFilters((prevFilters)=>{
      if (name === "industry") {
        const newIndustries = prevFilters.industry.includes(value)
          ? prevFilters.industry.filter((industry) => industry !== value) // Deselect industry
          : [...prevFilters.industry, value]; // Select industry
  
        return { ...prevFilters, [name]: newIndustries };
      }
  
      return {
        ...prevFilters,
        [name]: value, // Handle other filters normally
      };
    })
    

  switch(name){
    case "pressReleasing":
      setSelectedIndexPr(value);
      
      break;
    case "geoTag" :
      setSelectedIndexGeo(value);
      break;
      case "industry":
        setSelectedIndexIndustry(value);
        break;
      case "language" :
        setSelectedIndexLang(value);
        break;

      case "geoTarget":
        setSelectedIndexTarget(value);
       break;

  }
     

  };


  const handleReset = () => {
    setFilters({ 
      priceRange: [formData.priceRange[0],formData.priceRange[1]],
        outlets: 1,
        pressReleasing:formData.pressReleaseReady,
        industry:formData.industry,
        language:formData.language,
        geoTarget: '',
        
        geoTag:"",});

        setSelectedIndexPr("");
  
        setSelectedIndexGeo("");

          setSelectedIndexIndustry( "");

          setSelectedIndexLang("");
        
          setSelectedIndexTarget("");
          setSelectedIndex(0);
      

  };
const handleApplyFilter=()=>{

  setIsFiltering(true);

  //  toggleOverlay();
  const {outlets, pressReleasing, language,geoTag,industry,priceRange,geoTarget} = filters;

  
   
  console.log(actualPartners,"actual partners...")
  const filtered = actualPartners
  .map((partner) => {
    console.log(partner)
    const matchingPackages = Object.values(partner.packages).filter((pkg) => {
      
      const isWithinBudget = priceRange
        ? pkg.features.price <= priceRange[1] && pkg.features.price >= priceRange[0]
        : true;

      const isIndustryMatch = industry && industry.length > 0
        ? industry.some((ind) => pkg.features.industrySpecific.includes(ind))
        : true;

      const isPrReady = pressReleasing
        ? pressReleasing === "Yes"
          ? pkg.features.prWriting === "No" || pkg.features.prWriting === "Included"
          : pkg.features.prWriting === "Included"
        : true;

       
      const isGeoTag = geoTag
        ? pkg.features.googleNewsTagging === geoTag
        : true;

        const parsedOutlets = parseInt(outlets, 10);
const parsedTotalMediaOutlets = parseInt(pkg.features.totalMediaOutlets, 10);

const inMediaOutlets = outlets
  ? parsedTotalMediaOutlets >= parsedOutlets
  : true;

  
  // const isDeliveryTimeMatch = formData.deliveryTime
  // ? ["1-2 Days", "2-3 Days", "3-4 Days"].some((range) => {
      
  //     // Remove " Days" from the range string and split by "-"
  //     const [start, end] = range.replace(" Days", "").split("-").map(Number); 
      
  //     const selectedDay = parseInt(formData.deliveryTime, 10); // Get numeric value for the selected day
  //     const [pkgStart, pkgEnd] = pkg.features.deliveryTime.replace(" Days", "").split("-").map(Number); // Get start and end range for package

  //     // Check if selectedDay falls within the range of the package
  //     return selectedDay >= pkgStart && selectedDay <= pkgEnd;
  //   })
  // : true;


  // console.log(isDeliveryTimeMatch);


      return isWithinBudget && isIndustryMatch&& isPrReady && isGeoTag  && inMediaOutlets 
      //&& isDeliveryTimeMatch   ;
    });
 

    // Return only partners that have matching packages
    if (matchingPackages.length > 0) {
      return {
        ...partner,
        packages: matchingPackages, // only include matching packages
      };
    }

    return null;
  })
  .filter(partner => partner !== null); // Remove partners with no matching packages

console.log(beforeSortPartners);

  
  setFilteredPartners(filtered);
  setBeforeSortPartners(filtered);
  console.log(filteredPartners,"showinf filtereddddd partners")
 
}

const handleSliderChange = (newRange) => {
  setFilters((prevData)=>({
    ...prevData,
    priceRange:newRange,
    
  }))
}
console.log("showing actual partners", actualPartners);
//to show on the navabr


const displayItems = isFiltering ? filteredPartners : actualPartners;
console.log(displayItems)
const noDataFound = isFiltering && filteredPartners.length === 0;
  // ;

  const displayCount =displayItems.length;
  // isFiltering && filteredPartners.length === 0 ? 0 : 
  const isMobile = useMediaQuery("(max-width:1000px)");



console.log("displaying selected service", selectedServices)

   // Open the menu
   const handleClick = (menuId) => {
    setOpenMenu(prevState => (prevState === menuId ? null : menuId));
  };

  // Close the menu
  const handleClose = () => {
    setOpenMenu(null);
  };

  // sort by selection order
  const handleRadioChangeSort = (event) => {
    const index = parseInt(event.target.value, 10);
 
    console.log(beforeSortPartners)
    setSelectedIndex(index);
    switch(index){
      case 0:
        setFilteredPartners([...beforeSortPartners]);
        break;
        case 1:
          const sortedPartners = [...beforeSortPartners].sort((a, b) => {
            // Sort packages for partner 'a' by price
            const sortedPackagesA = a.packages.sort((pkgA, pkgB) =>  (pkgB.features?.price || 0)- (pkgA.features?.price || 0) );
        
            // Sort packages for partner 'b' by price
            const sortedPackagesB = b.packages.sort((pkgA, pkgB) =>  (pkgB.features?.price || 0)-(pkgA.features?.price || 0) );
        
            // Compare the lowest prices of the sorted packages first
            return (sortedPackagesB[0]?.features?.price || 0) - (sortedPackagesA[0]?.features?.price || 0);
          });
        
          console.log('Sorted partners based on each package:', sortedPartners);
          setFilteredPartners(sortedPartners);
          break;
          case 2:
            const sortedPartners2 = [...beforeSortPartners].sort((a, b) => {
              // Sort packages for partner 'a' by price
              const sortedPackagesA = a.packages.sort((pkgA, pkgB) =>  (pkgA.features?.price || 0) -(pkgB.features?.price || 0) );
          
              // Sort packages for partner 'b' by price
              const sortedPackagesB = b.packages.sort((pkgA, pkgB) => (pkgA.features?.price || 0) - (pkgB.features?.price || 0));
          
              // Compare the lowest prices of the sorted packages first
              return  (sortedPackagesA[0]?.features?.price || 0)-(sortedPackagesB[0]?.features?.price || 0) ;
            });
        setFilteredPartners(sortedPartners2);
        break;
    }
    handleClose();
    
  };

 


  const whyChooseBizowl = [
    {
      image: Growth,
      reason: "Business Growth Roadmap",
      alt: "Growth",
    },
    {
      image: Price,
      reason: "Best Price",
      alt: "BestPrice",
    },
    {
      image: Support,
      reason: "24*7 Support Assistance",
      alt: "Support",
    },
    {
      image: Quality,
      reason: "Best Quality, No disputes",
      alt: "Quality",
    },
    {
      image: Compare,
      reason: "Compare & Choose",
      alt: "Compare",
    },
    {
      image: Experience,
      reason: "Smoother Experience",
      alt: "Experience",
    },
  ];

  const adjustOpacity = (color, opacity) => {
    return color.replace(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)/, 
      (match, r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${opacity})`);
  };

  const darkenColor = (color, percent) => {
    return color.replace(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)/, (match, r, g, b, a) => {
      const factor = (100 - percent) / 100;
      const rDark = Math.floor(r * factor);
      const gDark = Math.floor(g * factor);
      const bDark = Math.floor(b * factor);
      return `rgba(${rDark}, ${gDark}, ${bDark}, ${a})`;
    });
  };

  const colors = [
    
  //    "rgba(194, 218, 243, 1)",  
  // "rgba(213, 217, 237, 1)",  
  // "rgba(231, 214, 226, 1)", 
  // "rgba(242, 236, 213, 1)",   
  // "rgba(213, 236, 231, 1)",  
  // "rgba(171, 188, 203, 1)",

  "rgba(112, 166, 230,1)",
  "rgba(160, 163, 216,1)",
  "rgba(207, 157, 187, 1)",
  "rgba(234, 211, 156, 1)",
  "rgba(161, 212, 201, 1)",
  "rgba(14, 55, 104, 1)",
  ];

  
 

  useEffect(() => {
    const fetchPartners = async () => {
      setIsLoading(true);
      try {
        
        const querySnapshot = await getDocs(collection(partnerDB, "newPrData"));
        const getPartners = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          console.log(data);
           const baseColor = colors[Math.floor(Math.random() * colors.length)];
           const bgColor = adjustOpacity(baseColor, 0.3); // Low opacity for bgColor
           const borderColor = adjustOpacity(baseColor, 1); // Higher opacity for borderColor
           const letterColor = adjustOpacity(baseColor, 1); // Same as borderColor for letters
          
          const letters = [
            { letter: data.name.substring(0, 3).toUpperCase(), color: letterColor },
          ];
          
  
  
          return {
            ...data,
            id: data.id,
            letters,
            bgColor: bgColor,
            borderColor: borderColor,
          };
         
        });
        // console.log("gettting partners", getPartners)
       
       setActualPartners(getPartners)

        const budgetLimit = formData.priceRange; // Assuming formData.priceRange is defined
        const industrySet = formData.industry;
        const deliveryMatch = formData.deliveryTime;
        console.log(formData, "form ka data");
        // console.log(actualPartners, "get actual aprtners ka data ");
  
        // const filteredPartners = getPartners.filter((partner) => {
        //   const isWithinBudget = budgetLimit
        //     ? partner.packages.every((p) => p.features.price <= budgetLimit[1] && p.features.price  >=budgetLimit[0])
        //     : true;

        //   // const isIndustryMatch = formData.industry
        //   //   ? partner.packages.some((p) =>
        //   //       p.features.industrySpecific
        //   //         .includes(industrySet)
        //   //     )
        //   //   : true;
  
        //   const isDeliveryTimeMatch = deliveryMatch
        //     ? partner.packages.some(
        //         (p) => (p.features.deliveryTime === "2 Days"||"3 Days" ||"4 Days") ? p.features.deliveryTime===deliveryMatch :true
        //       )
        //     : true;
  
        //   return  isWithinBudget ;
       
        // });
  
       
  
        // setPartners(filteredPartners);
        // console.log("showing filtered partners",partners)
       
        setIsLoading(false);
        handleApplyFilter()
        
      } catch (error) {
        console.error("Error fetching partners data:", error);
      }

 
      
    };
   
    
    fetchPartners();

    
  }, []);

  useEffect(() => {
    handleApplyFilter();
}, [filters,actualPartners]);

  const handleCheckboxChange = (event, serviceId, index) => {

    console.log(serviceId,index)
    if ((isMobile ? selectedServices.length >= 2 : selectedServices.length >= 3) && event.target.checked) {
      event.target.checked = false;
      return;
    }
    const key =  `${serviceId}-${index}`;

    setIsChecked((prevChecked) => ({
      ...prevChecked,
      [key]: !prevChecked[key],
    }));

    if (event.target.checked) {
      setComparisonTrayVisible(true);
      handleAddToComparisonTray(serviceId,index);
    } else {
      setSelectedServices((prevServices) =>
        prevServices.filter((service) =>service.id!= serviceId)
      );
      if (selectedServices.length === 0) {
        setComparisonTrayVisible(false);
      }
    }
  };

  const handleAddToComparisonTray = (serviceId,index) => {
    setSelectedServices((prevServices) => [
      ...prevServices,
      { id: serviceId, index: index },
    ]);
  };

  // const handleCompare = () => {
  //   const selectedService = selectedServices.filter((service) => service.selected);
  //   navigate(`/service/compare?services=${selectedService.map((service) => service.id).join(',')}`);
  // };

  const handleCompare = () => {
    const queryString = selectedServices.map(service=>`${service.id}-${service.index}`).join(",");
    
    navigate(`/services/press-release-distribution/quote-details/compare-plans/?services=${queryString}`);
  };

  const handleCancel = () => {
    setComparisonTrayVisible(false);
    setSelectedServices([]);
    setIsChecked({});
  };

  const handleRemoveFromComparisonTray = (serviceId,index) => {
    setSelectedServices((prevServices) => {
      const newServices = prevServices.filter((service) => service.id !== serviceId);
      if (newServices.length === 0) {
        setComparisonTrayVisible(false);
        setSelectedServices([]);
        setIsChecked({});
      } else {
        setIsChecked((prevChecked) => {
          // Remove the `serviceId` from the `isChecked` state
          const { [`${serviceId}-${index}`]: removed, ...rest } = prevChecked;
          return rest;
        });
        console.log(isChecked,"checking cecked id")
      }
      return newServices;
    });
  };
  

  
// click to open whatsapp in browser

  const handleClickWhatsApp = () => {
    // Replace with the actual WhatsApp number you want to use
    const whatsappNumber = "919354651433";
    const whatsappURL = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappURL, '_blank');
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }


 
// handle more options
const handleMoreOption=(id)=>{
  
  setVisibleItemId(prevId => (prevId === id ? null : id));
  setShowMoreContent(prevId=>(prevId===id?null:id));


}


const handleMoreContent =(card)=>{
  console.log("coming caard", card)
  console.log(card.packages)
 
  return (
   
    <div className="d-flex " style={{
      marginTop:"-50px",
    
    }}>
    <div
    style={{
      marginBottom: comparisonTrayVisible ? "100px" : "0px",

      transition: "margin-bottom 0.5s ease-in-out",
    }}
  >
      {Object.values(card?.packages)?.slice(1).map((item,index) => (
      
                <div className="m-5 d-flex align-items-center" key={index}>
                  <div
                    key={index}
                    className="card"
                    style={{
                      height: isMobile ? "6rem" : "13rem",
                      width: isMobile ? "9rem" : "12rem",
                     background: `conic-gradient(from 220deg at calc(100% - 60px) calc(100% - 50px), ${card.bgColor} 80%, white 100%)`,
                     borderColor:"transparent",
                      borderTopLeftRadius: "25px",
                      borderBottomLeftRadius: "25px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxShadow: "0 0 0px 4px rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          border: "3px solid",
                          borderColor: card?.borderColor,
                          borderRadius: "20px",
                          padding: "0.5rem",
                          width: isMobile ? "100%" : "80%",
                          fontSize: "bold",
                          backgroundColor: "white",
                        }}
                      >
                        {card?.letters.map((letter, index) => (
                          <div key={index} style={{ letterSpacing: "10px" }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "2rem",
                                color: letter?.color,
                              }}
                            >
                              {letter?.letter}
                            </span>
                           
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      boxShadow: "0.25rem 0.25rem 0.9375rem 0px #407BFF6B",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                    }}
                  >
                    <div
                      className="card"
                      style={{
                        height: isMobile ? "6rem" : "13rem",
                        width: isMobile ? "14rem" : "35rem",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: "15px",
                        borderBottomRightRadius: "15px",
                        border: "none",
                      }}
                    >
                    
                      <div
                        className="card-body d-flex justify-content-between"
                        style={{ marginBottom: "-20px" }}
                      >
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex flex-column align-items-start">
                            <h6 style={{fontSize:"0.9rem"}}>Distribution Outlets</h6>
                            <p style={{ fontWeight: "bold" }}>{item?.features?.totalMediaOutlets}+</p>
                          </div>
                          <div
                            className="d-flex flex-column align-items-start"
                            style={{
                              width: !isMobile && "70%",
                              textAlign: "left",
                            }}
                          >
                            <h6 style={{fontSize:"0.9rem"}}>Language</h6>
                            <p style={{ fontWeight: "bold" }}>
                              English, Hindi, Others
                            </p>
                          </div>
                        </div>
                        <div
                          className="d-flex flex-column justify-content-start"
                          style={{ fontSize: "0.9rem" }}
                        >
                          <div>
                            <h6 style={{fontSize:"0.9rem"}}>Delivery</h6>
                            <p style={{ fontWeight: "bold" }}>{item?.features?.deliveryTime}</p>
                          </div>
                          <div>
                            <h6 style={{fontSize:"0.9rem"}}>Geo-Tagging</h6>
                            <p style={{ fontWeight: "bold" }}>{item?.features?.googleNewsTagging ? "Yes" : "No"}</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <h6 style={{ fontSize: "small" }}>
                              Total Price Included GST
                            </h6>
                            <p style={{ fontWeight: "bold" }}>₹{item?.features?.price}</p>
                            <button
                              className="btn btn-primary btn-sm"
                              style={{
                                borderRadius: "12px",
                                padding: "6px 12px",
                              }}
                           onClick={()=>navigate(`/services/press-release-distribution/quote-details/service-summary/?id=${card?.id}&index=${item.name}`)} >
                              Get Started
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                            backgroundColor:"#F1FFFC",
                            borderRadius: "0px 0px 15px 0px",
                              zIndex:"200"
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "right",
                            marginRight: "15px",
                          
                          }}
                        >
                          <Checkbox
                        
                            checked={isChecked[`${card.id}-${index+1}`] || false}
                            onChange={(event) =>
                              handleCheckboxChange(event, card.id,index+1)
                              
                            }
                            disabled={
                              (isMobile ? selectedServices.length >= 2 : selectedServices.length >= 3) &&
                              !item.isChecked
                            }
                          />
                          <Typography>Add To Compare</Typography>
                        </div>

                        
                      </div>




                    


                    </div>
                   
                  </div>
                  
                </div>
              ))}



    </div>
    </div>
   
  )
  
}
  return (
    <div className="card" >
      <div className="card-body" style={{ backgroundColor: "#c3ddf938", padding: isMobile ? '0' : '', }}>
        { isMobile ? (
          <div className="sticky top-0 left-0 w-screen bg-white  flex items-center justify-between p-3 "
      style={{
    boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.25)', // Custom drop shadow
    zIndex:300
  }}>
        <div>
        {
          <Typography
  variant="body2"
  sx={{
    display: 'flex',
    alignItems: 'center',
    fontSize: '.85rem',
    fontWeight:"600" // Adjust font size if needed
  }}
>
  <span style={{ fontSize: '2.5rem', marginRight: '0.5rem' }}>
    <MdKeyboardArrowLeft />
  </span>
  Showing {displayCount} Plans
</Typography>

        }


        </div>

        <div>
        <img
          src={BizowalLogo}
          alt="BizowlLogo"
          width="70px"
        />
        </div>
      </div>

        ):(
          <div className="d-flex justify-content-between " style={{
       boxShadow: "0 4px 6.4px 0 #c6cac9",
       margin:"0px -15px 30px -15px",
       position:"fixed",
     top:"0",
       right:"0",
       left:"0",
       zIndex:"9999",
       backgroundColor:"#f7fcfb",
  
     
    }}>
          <div style={{
            display: "flex",
            alignItems: "center",
            justifyContent:"space-between",
            width:"90%",
            // marginTop:'8px',
            marginLeft:'4rem',
            backgroundColor:"#f7fcfb",
          paddingTop:"15px",
          paddingBottom:"15px",


          
          }}>
          <div className="d-flex align-items-center cursor-pointer" onClick={()=>navigate("/")}>
            <img
              src={BizowalLogo}
              alt="BizowlLogo"
              width={isMobile ? "120px" : "200px"}
              
            />
          </div>
         
  <div className="d-flex align-items-center gap-1 cursor-pointer" onClick={handleClickWhatsApp}>
    <img
      src={PhonePic}
      alt="PhonePic"
      width={isMobile ? "30px" : "40px"}
    />
    <p>Need help?</p>
  </div>

        </div>
          </div>
        )

        }


        {!isMobile && (
          <div className="d-flex " style={{
            marginTop:"6rem"
          }}>
            <div className="m-5" style={{ width: "20rem" }}>
            <div>
      <div
        className="card "
        style={{
          borderRadius:"25px"
        }}
      >
        <div
          className="flex flex-col  align-items-center card-body"
          style={{
            boxShadow: "0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
            borderRadius: `25px`,
            cursor: "pointer"
          }}
          onClick={()=>handleClick('sortby')}
        >
         <div className="flex justify-between w-[100%] " style={{
                      marginBottom:""
                    }}>
         <div>Sort By</div>
          <div
            style={{
              border: "1px solid",
              borderColor: "white",
              borderRadius: "100%",
              boxShadow: "0rem 0.25rem 0.25rem 0rem #407BFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
               width:"1.5rem",
              height:"1.5rem"
            }}
          >
            <img
              src={DownArrowPic}
              alt="DownArrowPic"
              width="28rem"
              height="30rem"
              style={{ padding: "0.3rem",  borderRadius:"100%" }}
            
            />
          </div>
         </div>

         {openMenu==='sortby' && (
  <div >
    <StyledFormControl component="fieldset" >
      {menuOptions.map((option, index) => (
        <StyledFormControlLabel
          key={option}
          control={
            <Radio
              checked={index === selectedIndex}
              onChange={handleRadioChangeSort}
              value={index}
            />
          }
          label={option}
        
        
        />
      ))}
    </StyledFormControl>
  </div>
)}

        </div>

        


      </div>

      
    </div>
    {/* filterss */}
              <div className="card" style={{ marginTop: "1rem",borderRadius:"25px" }}>
                <div
                  className="card-body"
                  style={{
                    boxShadow: "0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
                    borderRadius:"25px",
                    
                    
                  }}
                >
                  <div className=" flex flex-col   mb-2" style={{
                    alignItems:"start",
                    
                  }}>
                    <h5 className="text-xl mt-3">Filter</h5>
                    <div className="border border-b-gray-500 w-[100%] mt-2 mb-3"></div>
                  </div>
                  

                  {/* budget section */}
                  <div className="flex-col justify-content-between align-items-center cursor-pointer" onClick={()=>handleClick("budget")}>

                    <div className="flex justify-between w-[100%] " style={{
                      marginBottom:20
                    }}>
                    <h6 style={{fontSize:"0.9rem"}}>Budget</h6>
                    <img
                      src={BlueDownArrow}
                      alt="BlueDownArrow"
                      width="17rem"
                      height="10rem"
                    />
                    </div>
                    {/* budget slider */}
                    {
                      openMenu==="budget" &&(
                        <div
                            style={{
                            display:"block",
                            width: '',
                            }}
                        >
                            <div style={{ position: 'relative' }}>
                            <ReactSlider
          value={filters.priceRange}
          onChange={handleSliderChange}
          min={0}
          max={30000}
          step={500}
          className="w-full h-2 bg-gray-300 rounded-full"
          thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px] "
          trackClassName="bg-blue-500 rounded-full "
          renderThumb={(props) => <div {...props}  />} // Remove default value display
        />
        <div className="flex justify-between text-sm text-gray-600 mt-2">
          <span>₹{filters.priceRange[0]}</span>
          <span>₹{filters.priceRange[1]}</span>
        </div>

                            {/* <div
            style={{
              position: 'absolute',
              left: `130px`, // Adjust the -20px offset based on the width of the box
              top: '25px', // Adjust the vertical position as needed
              backgroundColor: 'white',
              border: '1px solid gray',
              padding: '2px 8px',
              borderRadius: '10px',
              whiteSpace: 'nowrap',
              transform: 'translateX(-50%)', // Center the box horizontally
            }}
          >
            {filters.priceRange}
          </div> */}
                            </div>
                    </div>
                      )
                    }
                     <div className="border border-b-gray-500 w-[100%] mt-2" style={{
  marginBottom:"20px"
}}></div>
                  </div>
                  

                  {/* press release writing */}
                  <div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer"
                  onClick={()=>handleClick("prWriting")}>

                  <div className="flex justify-between w-[100%] " style={{
                      marginBottom:20
                    }}>        <h6 style={{fontSize:"0.9rem"}}>Press Releasing writing (Ready or not ?) </h6>
                      <img
                        src={BlueDownArrow}
                        alt="BlueDownArrow"
                        width="17rem"
                        height="10rem"
                      />

                    </div>

                    <div className="">

      

                    {openMenu==="prWriting" && (
  <div >
      <StyledFormControl component="fieldset" >
      {options.map((option, index) => (
        <StyledFormControlLabel
          key={option}
          control={
            <Radio
              checked={filters.pressReleasing === option}
              name="pressReleasing"
              onChange={handleFilter} 
              value={option}
            />
          }
          label={option}
        
        />
      ))}
    </StyledFormControl>
  </div>
)}
 <div className="border border-b-gray-500 w-[100%] mt-2" style={{
  marginBottom:"20px"
}}></div>
    </div>
                  

                  </div>
                 

        {/* for media outlets */}
                  <div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer"
                    onClick={()=>handleClick("media")}>
                   <div className="flex justify-between w-[100%] " style={{
                      marginBottom:20
                    }}>
                   <h6 style={{fontSize:"0.9rem"}}>No. Media Outlets</h6>
                    <img
                      src={BlueDownArrow}
                      alt="BlueDownArrow"
                      width="17rem"
                      height="10rem"
                    />
                   </div>
                   {
                      openMenu==="media" &&(
                        <div
                            style={{
                            display:"block",
                            width: '',
                            }}
                        >
                            <div style={{ position: 'relative' }}>
                            <input
                                id="outlets"
                                type="range"
                                name="outlets"
                                min="0"
                                max="1000"
                                step="5"
                                value={filters.outlets}
                                onChange={handleFilter}
                                className="w-full h-2 bg-gray-200 rounded-full appearance-none cursor-pointer "
                            />
                            <div
            style={{
              position: 'relative',
              left: `130px`, // Adjust the -20px offset based on the width of the box
              top: '2px', // Adjust the vertical position as needed
              backgroundColor: 'white',
              border: '1px solid gray',
              padding: '1px 8px',
              borderRadius: '10px',
              whiteSpace: 'nowrap',
              transform: 'translateX(-50%)', // Center the box horizontally,
              width:"20%"
            }}
          >
            {filters.outlets}+
          </div>
                            
                            </div>
                    </div>
                      )
                    }

                     <div className="border border-b-gray-500 w-[100%] mt-2" style={{
  marginBottom:"20px"
}}></div>
                
                 </div>
                 
                  {/* fro language */}
                  <div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer"
                  onClick={()=>handleClick("language")}>

                  <div className="flex justify-between w-[100%] " style={{
                      marginBottom:20
                    }}>        <h6 style={{fontSize:"0.9rem"}}>Language</h6>
                      <img
                        src={BlueDownArrow}
                        alt="BlueDownArrow"
                        width="17rem"
                        height="10rem"
                      />

                    </div>

                 

                    

                    {openMenu==="language" && (
  <div >
      <StyledFormControl component="fieldset" >
      {languages.map((option, index) => (
        <StyledFormControlLabel
          key={option}
          control={
            <Checkbox
              checked={filters.language.includes(option)}
              name="language"
              onChange={handleFilter}
              value={option}
            />
          }
          label={option}
        
        />
      ))}
    </StyledFormControl>
  </div>
)}
 <div className="border border-b-gray-500 w-[100%] mt-2" style={{
  marginBottom:"20px"
}}></div>
 
                  

                  </div>
                 

                  {/* for geo-tag */}
                  <div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer"
                  onClick={()=>handleClick("geo-tag")}>

                  <div className="flex justify-between w-[100%] " style={{
                      marginBottom:20
                    }}>        <h6 style={{fontSize:"0.9rem"}}>Geo Tag</h6>
                      <img
                        src={BlueDownArrow}
                        alt="BlueDownArrow"
                        width="17rem"
                        height="10rem"
                      />

                    </div>


                  

                    {openMenu==="geo-tag" && (
  <div >
      <StyledFormControl component="fieldset" >
      {options.map((option, index) => (
        <StyledFormControlLabel
          key={option}
          control={
            <Radio
              checked={filters.geoTag===option}
              onChange={handleFilter}
              name="geoTag"
              value={option}
            />
          }
          label={option}
        
        />
      ))}
    </StyledFormControl>
  </div>
)}
 <div className="border border-b-gray-500 w-[100%] mt-2" style={{
  marginBottom:"20px"
}}></div>
      
   
                  

                 
                  </div>

                  {/* geo target */}
                  <div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer"
                  onClick={()=>handleClick("target")}>

                  <div className="flex justify-between w-[100%] " style={{
                      marginBottom:20
                    }}>        <h6 style={{fontSize:"0.9rem"}}>Geographical Target</h6>
                      <img
                        src={BlueDownArrow}
                        alt="BlueDownArrow"
                        width="17rem"
                        height="10rem"
                      />

                    </div>

                    <div className="">

      

                    {openMenu==="target" && (
  <div >
      <StyledFormControl component="fieldset" >
      {geoTargets.map((option, index) => (
        <StyledFormControlLabel
          key={option}
          control={
            <Checkbox
              checked={filters.geoTarget.includes(option)}
              onChange={handleFilter}
              name="geoTarget"
              value={option}
            />
          }
          label={option}
        
        />
      ))}
    </StyledFormControl>
  </div>
)}
 <div className="border border-b-gray-500 w-[100%] mt-2" style={{
  marginBottom:"20px"
}}></div>
    </div>
                  

                  </div>
                  
{/* for industries */}
<div className="flex-col justify-content-between align-items-center mt-2 cursor-pointer"
                  onClick={()=>handleClick("industry")}>

                  <div className="flex justify-between w-[100%] " style={{
                      marginBottom:20
                    }}>        <h6 style={{fontSize:"0.9rem"}}>Industry Specific</h6>
                      <img
                        src={BlueDownArrow}
                        alt="BlueDownArrow"
                        width="17rem"
                        height="10rem"
                      />

                    </div>

                    <div className="relative inline-block text-left">

                    <div>

                    </div>

                    {openMenu==="industry" && (
  <div >
      <StyledFormControl component="fieldset" >
      {industries.map((option, index) => (
        <StyledFormControlLabel
          key={option}
          control={
        <Checkbox
          checked={filters.industry.includes(option)} // Check if the industry is in the array
          onChange={handleFilter}
          name="industry"
          value={option}
        />
          }
          label={option}
        
        />
      ))}
    </StyledFormControl>
  </div>
)}
 <div className="border border-b-gray-500 w-[100%] mt-2" style={{
  marginBottom:"20px"
}}></div>
      
    </div>
                  

                  </div>

                  <button type='reset' onClick={handleReset} style={{
            padding: '5px 20px',
            marginTop:"10px",
            borderRadius: '10px',
            backgroundColor: '#1c6ed0',
            color: 'white',
            cursor: 'pointer',
            marginLeft: '10px',
            fontWeight: 'bold',
            fontSize: '16px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
        }}>Reset</button>
                 
                </div>

              </div>

{/* why choose bizowl */}
              <div
                className="card"
                style={{
                  marginTop: "10rem",
                  backgroundColor: "#F6F6FD",
                  borderRadius: "25px",
                  boxShadow:
                    "0.3125rem 0.25rem 0.4375rem 0rem rgba(121, 168, 224, 0.65)",
                }}
              >
                <div className="card-body" style={{
                  borderRadius:"25px"
                }}>
                  <h3 style={{fontSize:"1.8rem",textAlign:"left",color:"#1B2559"}}>Why Choose Bizowl?</h3>
                  {whyChooseBizowl?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "end",
                        marginTop: "0.5rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <img alt="" src={item?.image} width="30px" />
                      <h6 style={{ marginLeft: "1rem",fontSize:"small",textAlign:"left" }}>{item?.reason}</h6>
                    </div>
                  ))}
                </div>
              </div>
              {/* do not share thing */}
              <div
                className="card"
                style={{
                  marginTop: "5rem",
                  borderRadius: "25px",
                  boxShadow: `
    -5px -4px 7px 0px rgba(121, 168, 224, 0.55),
    5px 4px 7px 0px rgba(121, 168, 224, 0.55)
  `,
                }}
              >
                <div className="card-body" style={{
                  backgroundColor:"#F4F8FF",
                  alignItems: "start",
                  justifyContent: "center",
                  borderRadius:"25px",
                  padding:"0px 8px",
                 
                  
                  
                }}>
                  <div className="flex items-start justify-start gap-2 mt-5 mb-2 pl-2 pr-2">
                    <img src={SalesSupport} width="90px" />
                    <p className="text-sm text-left text-[#1B2559]">Our executives can help you select the best plan for your need.</p>
                  </div>
                  <div className="flex gap-2 justify-center items-center bg-white " style={{
                    maxWidth:"120px",
                    margin:"0 auto 40px auto",
                    borderRadius:"10px",
                     cursor: "pointer"
                  }} onClick={handleClickWhatsApp}>
                    <img src={Call} width="20px" style={{
                      marginTop:"10px"
                    }}/>
                    <p className="mt-2 text-[#1C6ED0] text-sm ">Talk to Us</p>
                  </div>
                </div>
              </div>
            </div>
            {/*actusal page and cards  */}
            <div
              style={{
                marginBottom: comparisonTrayVisible ? "100px" : "0px",

                transition: "margin-bottom 0.5s ease-in-out",
              }}
            >
            {noDataFound ? (<p className="text-black font-extrabold text-3xl text-justify">No data Found !! <br/><br/>But Don't Worry We are continuously adding more partners</p>):displayItems.map((item,index) => (
              <React.Fragment key={item.id}>
                <div className="m-5 d-flex align-items-center">
                  <div
                    key={item.id}
                    className="card"
                    style={{
                      height: isMobile ? "6rem" : "13rem",
                      width: isMobile ? "9rem" : "12rem",
                     background: `conic-gradient(from 220deg at calc(100% - 60px) calc(100% - 50px), ${item.bgColor} 80%, white 100%)`,
                     borderColor:"transparent",
                      borderTopLeftRadius: "25px",
                      borderBottomLeftRadius: "25px",
                      borderTopRightRadius: "0px",
                      borderBottomRightRadius: "0px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      boxShadow: "0 0 0px 4px rgba(255, 255, 255, 0.8)",
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          border: "3px solid",
                          borderColor: item?.borderColor,
                          borderRadius: "20px",
                          padding: "0.5rem",
                          width: isMobile ? "100%" : "80%",
                          fontSize: "bold",
                          backgroundColor: "white",
                        }}
                      >
                        {item?.letters.map((letter, index) => (
                          <div key={index} style={{ letterSpacing: "10px" }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "2rem",
                                color: letter?.color,
                              }}
                            >
                              {letter?.letter}
                            </span>
                            {/* <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "2rem",
                                color: letter?.color2,
                              }}
                            >
                              {letter?.letter2}
                            </span>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "2rem",
                                color: letter?.color3,
                              }}
                            >
                              {letter?.letter3}
                            </span> */}
                          </div>
                        ))}
                      </div>

                      
                    </div>
                  </div>
                  <div
                    style={{
                      boxShadow: "0.25rem 0.25rem 0.9375rem 0px #407BFF6B",
                      borderTopRightRadius: "15px",
                      borderBottomRightRadius: "15px",
                    }}
                  >
                    <div
                      className="card"
                      style={{
                        height: isMobile ? "6rem" : "13rem",
                        width: isMobile ? "14rem" : "35rem",
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                        borderTopRightRadius: "15px",
                        borderBottomRightRadius: "15px",
                        border: "none",
                      }}
                    >
                    
                      <div
                        className="card-body d-flex justify-content-between"
                        style={{ marginBottom: "-20px" }}
                      >
                        <div className="d-flex flex-column align-items-start">
                          <div className="d-flex flex-column align-items-start">
                            <h6 style={{fontSize:"0.9rem"}}>Distribution Outlets</h6>
                            <p style={{ fontWeight: "bold" }}>{item?.packages[0]?.features?.totalMediaOutlets}+</p>
                          </div>
                          <div
                            className="d-flex flex-column align-items-start"
                            style={{
                              width: !isMobile && "70%",
                              textAlign: "left",
                            }}
                          >
                            <h6 style={{fontSize:"0.9rem"}}>Language</h6>
                            <p style={{ fontWeight: "bold" }}>
                              English, Hindi, Others
                            </p>
                          </div>
                        </div>
                        <div
                          className="d-flex flex-column justify-content-start"
                          style={{ fontSize: "0.9rem" }}
                        >
                          <div>
                            <h6 style={{fontSize:"0.9rem"}}>Delivery</h6>
                            <p style={{ fontWeight: "bold" }}>{item?.packages[0]?.features?.deliveryTime}</p>
                          </div>
                          <div>
                            <h6 style={{fontSize:"0.9rem"}}>Geo-Tagging</h6>
                            <p style={{ fontWeight: "bold" }}>{item?.packages[0]?.features?.geoTag ? "Yes" : "No"}</p>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <h6 style={{ fontSize: "small" }}>
                              Total Price Included GST
                            </h6>
                            <p style={{ fontWeight: "bold" }}>₹{item?.packages[0]?.features?.price}</p>
                            <button
                              className="btn btn-primary btn-sm"
                              style={{
                                borderRadius: "12px",
                                padding: "6px 12px",
                              }}
                           onClick={()=>navigate(`/services/press-release-distribution/quote-details/service-summary/?id=${item?.id}&index=${item.packages[0].name}`)} >
                              Get Started
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "end",
                            backgroundColor:"#F1FFFC",
                            borderRadius: "0px 0px 15px 0px",
                              zIndex:"200"
                        }}
                      >
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "right",
                          }}
                        >
                          
                        </div>
                        <div
                          style={{
                            width: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "right",
                            marginRight: "15px",
                          
                          }}
                        >
                          <Checkbox
                        
                            checked={isChecked[`${item.id}-${0}`] || false}
                            onChange={(event) =>
                              handleCheckboxChange(event, item.id, 0)
                              
                            }
                            disabled={
                              (isMobile ? selectedServices.length >= 2 : selectedServices.length >= 3) &&
                              !item.isChecked
                            }
                          />
                          <Typography>Add To Compare</Typography>
                        </div>

                        
                      </div>

{/* for more option bar */}

{
  Object.keys(item.packages).length>0 &&(
    <div onClick={()=>handleMoreOption(item.id)} 
    id={item.id}
    style={{
                      width:"239px",
                      color:"#455A64",
                     position:"absolute",
                     display:"flex",
                     justifyContent:"space-around",
                     alignItems:"center",
                     top:"209px",
                     left:"20%",
                     
                     borderTop:"0px",
                     fontSize:"x-small",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    zIndex:"100",
                    backgroundColor:"white",
                     boxShadow: "0px 2px 4px 0px #C4C6C6",
                     cursor:"pointer"

                    }}>
                     {visibleItemId === item.id ? (
              <>
                <p>hide options</p>
                <p style={{ fontSize: "medium" }}>
                  <MdKeyboardArrowUp />
                </p>
              </>
            ) : (
              <>
                <p>{Object.keys(item.packages).length-1}{" "}more option from{' '} {item?.name.slice(0,3).toUpperCase()}</p>
                <p style={{ fontSize: "medium" }}>
                  <MdKeyboardArrowDown />
                </p>
              </>
            )}
                    
                     </div>

  )
}

      


                    </div>
                   
                  </div>
                  
                 
              
                </div>

                {showMoreContent === item.id &&(
                  <div >{handleMoreContent(item)}</div>
                )}
                </React.Fragment>
              ))}
            </div>
          </div>
          
        )}

       

        {/* filter component */}

        {isMobile && (
        <div className="flex justify-between "
          style={{
            marginTop:"20px",
            marginLeft:'3%'

          }}>

           <div>
      <Typography
        variant="body1"
        sx={{
          border: "2px solid",
          borderColor: `${selectedIndex != null ? "#a3c2f7" : "#e5e7eb"}`,
          borderRadius: "100px",
          padding: "0rem 0.5rem",
          fontWeight: "400",
          display: "flex",
          alignItems: "center",
          boxShadow: `${selectedIndex != null ? '0 1px 2px rgba(0, 102, 255, 0.2)' : '0 1px 2px rgba(0, 0, 0, 0.05)'}`,
          cursor: "pointer",
          transition: "all 0.3s ease",
          position:"relative"
        }}
        onClick={()=>handleClick("sortby")}
      >
             Sort By
        <span style={{ marginLeft: '0.5rem' }}>
        {Boolean(openMenu) ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </span>


        {openMenu==='sortby' && (
  <div className="absolute flex  z-2">
    <StyledFormControl component="fieldset" style={{
      minWidth:"170px",
      minHeight:"180px",
      backgroundColor:"white",
      borderRadius:"0px 20px 20px 20px",
     marginTop:"13rem",
      paddingLeft:"20px",
      paddingTop:"20px",
      marginLeft:"-15px"



    }}>
      {menuOptions.map((option, index) => (
        <StyledFormControlLabel
          key={option}
          control={
            <Radio
              checked={index === selectedIndex}
              onChange={handleRadioChangeSort}
              value={index}
            />
          }
          label={option}
          className="text-left"

          
        
        />
      ))}
    </StyledFormControl>
  </div>
)}
   
       
      </Typography>
   
      
    </div>
   
    <div>
      <div
        style={{
          border: '2px solid #e5e7eb',
          borderRadius: '100px',
          boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
          padding: '0rem 1rem',
          fontSize: '1.5rem',
          marginRight: '-2px',
          cursor: 'pointer'
        }}
        onClick={toggleOverlay}
      >
        <img src={Filter} alt="Filter" />
      </div>
      {/* Putting Filter page */}
      <CommonFilter showOverlay={showOverlay} setShowOverlay={setShowOverlay} toggleOverlay ={toggleOverlay } filters={filters} setFilters={setFilters} handleFilter={handleFilter} handleReset={handleReset}   
      // handleApplyFilter={handleApplyFilter}
        handleClick={handleClick}
        openMenu={openMenu}
        StyledFormControl={StyledFormControl}
        StyledFormControlLabel={StyledFormControlLabel}
        selectedIndexPr={selectedIndexPr}
        selectedIndexLang={selectedIndexLang}
        selectedIndexGeo={selectedIndexGeo}
        selectedIndexTarget={selectedIndexTarget}
        selectedIndexIndustry={selectedIndexIndustry}
        handleSliderChange={handleSliderChange}
 />

    </div>
        </div>
        )}
     
{/* comparison tray  */}
        {comparisonTrayVisible && (
          <div className="comparison-tray"
          style={{
            display:"flex",
            flexDirection:`${isMobile&&("row")}`,
            gap:`${isMobile&&("70px")}`,
            zIndex:"1000"
          }}>
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: `${isMobile?"column":"row"}`,
                justifyContent: "space-between",
                alignItems: "center",
                width: isMobile ? "100%" : "70%",
              }}
            >
              {actualPartners
                .filter((partner) => selectedServices.some((service) => service.id === partner.id))
                .map((partner) => {
                  const {id} =partner;
                  const index = selectedServices.find((service) => service.id === id).index;
                  return (
                  <li
                    style={{
                      listStyle: "none",
                      padding: !isMobile && "5px",
                      flexBasis: "auto",
                      flexGrow: 1,
                      flexShrink:0,
                      margin: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                      display: "flex", // Ensure the li itself is a flex container
                      flexDirection: "column", // Stack children vertically
                      alignItems: `${isMobile?"start":"center"}`, // Center-align children horizontally
                      textAlign: "center",
                      position: "relative",
                      width:`${isMobile ?"100%":""}`,
                
                     
                    }}
                    key={id}
                  >
                    <CloseIcon
                      className="close-icon"
                      onClick={() => handleRemoveFromComparisonTray(id,index)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer",
                      }}
                    />
                    <div
                      className="d-flex"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        height: !isMobile && "100px",
                      }}
                    >
                      {/* Card structure */}
                      <div
                        className="card"
                        style={{
                          height: isMobile ? "5rem" : "2rem",
                          width: isMobile ? "3rem" : "12rem",
                          border: "0.2rem solid",
                          borderColor: "white",
                          
                        }}
                      >
                        <div
                          className="card-body"
                          style={{
                            display: "flex",
                           
                            justifyContent: "space-between",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: `${isMobile ?"5px":"5rem"}`, //gap betweenprice and letter div
                          }}
                        >
                          <div
                            className={
                              isMobile
                                ? "d-flex justify-content-center"
                                : "d-flex justify-content-center"
                            }
                            style={{
                              border: "2px solid",
                              borderColor: partner.borderColor,
                              borderRadius: "0.5rem",
                              padding: "0.5rem",
                              width: !isMobile && "100%",
                              height: "50px",
                              fontSize: "large",
                              backgroundColor: "#F7FCFB",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {/* Safely map over service.letters */}
                            {(partner.letters || []).map((letter) => (
                              <div key={letter.id}>
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: isMobile ? "1rem" : "2rem",
                                    color: letter.color1,
                                  }}
                                >
                                  {letter?.letter}
                                </span>
                               
                              </div>
                            ))}
                          </div>
                          <p style={{ color: "#407BFF", fontSize: "small", textAlign:"justify"}}>
                          ₹{partner?.packages[index]?.features?.price}
                          </p>
                        </div>
                      </div>
                      {/* Additional card structure and content */}
                    </div>
                  </li>
                )
                })}
              {[...Array((isMobile ? 2 : 3) - selectedServices.length)].map((_, index) => (
                <li
                  key={`placeholder-${index}`}
                  style={{
                    listStyle: "none",
                    padding: "5px",
                    flexBasis: "auto",
                    flexGrow: 1,
                    margin: "10px",
                    borderRadius: "10px",
                    // boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                    border: "1px solid #407bff", // Add a border
                    display: "flex",
                    
                    justifyContent: "center",
                    alignItems: "center",
                    height: isMobile ? "6rem" : "7rem",
                  }}
                >
                  <Typography variant="body2" color="#407bff">
                    Add a Plan
                  </Typography>
                </li>
              ))}
            </ul>

            <div
              style={{
                display: "flex",
                flexDirection:`${isMobile ?"column":"row"}`,
                justifyContent: "space-between",
                width: !isMobile && "30%",
                padding: !isMobile && "0 3rem 0 3rem",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCompare}
                style={{ flex: 1, margin: "10px" }}
              >
                Compare
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancel}
                style={{ flex: 1, margin: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
{/*  mobile view code for actual cARd*/}
        {isMobile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            {noDataFound ? (<p className="text-black font-extrabold text-3xl text-justify">No data Found !! <br/><br/>But Don't Worry We are continuously adding more partners</p>):displayItems.map((item, index) => (
              <Center>

              {
                isMobile ?(
                  <div className="flex flex-col " style={{marginBottom:"25px"}} >
                  <Card
                  key={index}
                  sx={{
                    
                    maxWidth: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                   
                    borderRadius: "20px",
                    background: `linear-gradient(328deg, #ffffff 38%, ${item.bgColor} 50%) ;`
                  }}
                >
                

                 <CardContent >
                    <Grid container spacing={isMobile ? 1 : 2} >
                      {/* Top Side: Three Letter Name and Portfolio */}
                      <Grid item xs={12} sm={4}>
                        <Box
                        >
                          <div className="flex justify-between">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                border: "3px solid",
                                borderColor: item?.borderColor,
                                borderRadius: "15px",
                                // padding: "0.5rem",
                                width: "30%",
                                backgroundColor: "white",
                               
                              }}
                            >
                              {item?.letters.map((letter, idx) => (
                                <Typography
                                  key={idx}
                                  variant="h5"
                                  component="span"
                                >
                                  <div key={index} style={{ letterSpacing: "5px" }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                                color: letter?.color,
                                
                              }}
                            >
                              {letter?.letter}
                            </span>
                            </div>
                                </Typography>
                              ))}
                            </Box>
                       
                    <div className="flex gap-0">
                    <Checkbox
                        checked={isChecked[item.id] || false}
                        onChange={(event) =>
                          handleCheckboxChange(event, item.id)
                        }
                        disabled={
                          selectedServices.length >= 3 && !item.isChecked
                        }
                        className={`appearance-none border-2 border-gray-300 rounded-lg`}


                      />
                      <Typography variant="body2">Add To Compare</Typography>
                    </div>
                    </div>
                          
                        </Box>
                      </Grid>

                      {/* Middle: Features */}
                      <Grid item xs={12} sm={8} sx={{
                        padding:"0"
                      }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%'  }}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>Distribution Outlets</Typography>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'left' }}>{item?.packages[0]?.features?.totalMediaOutlets}+</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end',width: '100%' }}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>Language</Typography>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'right' }}>English, Hindi, Others</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' ,width: '100%' }}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>Delivery</Typography>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'left' }}>{item?.packages[0]?.features?.deliveryTime}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' ,width: '100%'  }}>
            <Typography variant="body2" sx={{ textAlign: 'right' }}>Geo-Tagging</Typography>
            <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'right' }}>{item?.packages[0]?.features?.geoTag}</Typography>
          </Box>
              </Grid>
            </Grid>
          </Grid>
                    </Grid>
                  </CardContent>
                  
                  {/* Pricing Section and Get Started Button */}
                  <div> 
                   <CardContent className="flex justify-between items-center">
                    <div className="flex flex-col">
                    <Typography variant="body2" gutterBottom>
                      Total Price Included GST
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mb: "10px", fontWeight: "bold" }}
                    >
                     ₹{item?.packages[0]?.features?.price}
                    </Typography>
                    </div>
                    
                   
                    

                  <Button
                      variant="contained"
    color="primary"
   
    style={{ textTransform: 'none', fontSize: '0.8rem', borderRadius: '0.5rem', padding: '0.3rem 0.3rem' }}

    onClick={()=>navigate(`/services/press-release-distribution/quote-details/service-summary/?id=${item.id}&index=${item.packages[0].name}`)}
                    >
                      Get Started
                    </Button>

                    </CardContent>
                  </div>
                   
 
                </Card> 

                <>
                {
                 
  item.packages.length>0 &&(
    <div onClick={()=>handleMoreOption(item.id)} 
    id={item.id}
    style={{
                      width:"239px",
                      color:"#455A64",
                     position:"",
                     display:"flex",
                     justifyContent:"space-around",
                     alignItems:"center",
                 
                     left:"20%",
                     
                     borderTop:"0px",
                     fontSize:"x-small",
                    borderBottomRightRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    zIndex:"100",
                    backgroundColor:"white",
                     boxShadow: "0px 2px 4px 0px #C4C6C6",
                     cursor:"pointer"

                    }}>
                     {visibleItemId === item.id ? (
              <>
                <p>hide options</p>
                <p style={{ fontSize: "medium" }}>
                  <MdKeyboardArrowUp />
                </p>
              </>
            ) : (
              <>
                <p>{item?.packages.length-1}{" "}more option from{' '} {item?.name.slice(0,3).toUpperCase()}</p>
                <p style={{ fontSize: "medium" }}>
                  <MdKeyboardArrowDown />
                </p>
              </>
            )}
                    
                     </div>

  )
}

{showMoreContent === item.id && (

  <div 
   > 
  {
  item?.packages.slice(1).map((pkg, index) => (
    <div
          key={index}
         
        >
<Center>
<Card
                  key={index}
                  sx={{
                    
                    maxWidth: "95%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                   
                    borderRadius: "20px",
                    background: `linear-gradient(328deg, #ffffff 38%, ${item.bgColor} 50%) ;`
                  }}
                >
                

                 <CardContent >
                    <Grid container spacing={isMobile ? 1 : 2} >
                      {/* Top Side: Three Letter Name and Portfolio */}
                      <Grid item xs={12} sm={4}>
                        <Box
                        >
                          <div className="flex justify-between">
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                border: "3px solid",
                                borderColor: item?.borderColor,
                                borderRadius: "15px",
                                // padding: "0.5rem",
                                width: "30%",
                                backgroundColor: "white",
                              }}
                            >
                              {item?.letters.map((letter, idx) => (
                                <Typography
                                  key={idx}
                                  variant="h5"
                                  component="span"
                                >
                                  <div key={index} style={{ letterSpacing: "5px" }}>
                            <span
                              style={{
                                fontWeight: "bold",
                                fontSize: "1.5rem",
                                color: letter?.color,
                                
                              }}
                            >
                              {letter?.letter}
                            </span>
                            </div>
                                </Typography>
                              ))}
                            </Box>
                       
                    <div className="flex gap-0">
                    <Checkbox
                        checked={isChecked[item.id] || false}
                        onChange={(event) =>
                          handleCheckboxChange(event, item.id)
                        }
                        disabled={
                          selectedServices.length >= 3 && !item.isChecked
                        }
                        className={`appearance-none border-2 border-gray-300 rounded-lg`}


                      />
                      <Typography variant="body2">Add To Compare</Typography>
                    </div>
                    </div>
                          
                        </Box>
                      </Grid>

                      {/* Middle: Features */}
                      <Grid item xs={12} sm={8} sx={{
                        padding:"0"
                      }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%'  }}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>Distribution Outlets</Typography>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'left' }}>{pkg?.features?.totalMediaOutlets}+</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end',width: '100%' }}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>Language</Typography>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'right' }}>English, Hindi, Others</Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' ,width: '100%' }}>
                <Typography variant="body2" sx={{ textAlign: 'left' }}>Delivery</Typography>
                <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'left' }}>{pkg?.features?.deliveryTime}</Typography>
                </Box>
              </Grid>
              <Grid item xs={6} >
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' ,width: '100%'  }}>
            <Typography variant="body2" sx={{ textAlign: 'right' }}>Geo-Tagging</Typography>
            <Typography variant="body2" fontWeight="bold" sx={{ textAlign: 'right' }}>{pkg?.features?.geoTag}</Typography>
          </Box>
              </Grid>
            </Grid>
          </Grid>
                    </Grid>
                  </CardContent>
                  
                  {/* Pricing Section and Get Started Button */}
                  <div> 
                   <CardContent className="flex justify-between items-center">
                    <div className="flex flex-col">
                    <Typography variant="body2" gutterBottom>
                      Total Price Included GST
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ mb: "10px", fontWeight: "bold" }}
                    >
                     ₹{pkg?.features?.price}
                    </Typography>
                    </div>
                    
                   
                    

                  <Button
                      variant="contained"
    color="primary"
   
    style={{ textTransform: 'none', fontSize: '0.8rem', borderRadius: '0.5rem', padding: '0.3rem 0.3rem' }}

    onClick={()=>navigate(`/services/press-release-distribution/quote-details/service-summary/?id=${item.id}&index=${pkg.name}`)}
                    >
                      Get Started
                    </Button>

                    </CardContent>
                  </div>
                   
 
                </Card> 
</Center>
</div>
  ))

  }
   </div>
  )}
</>
                </div>

                  ):(
                 ""
                )
              }
            
             
              </Center>

            ))}
          </div>
         
        )}

      
      </div>
     
    </div>
  );
};

export default ServicePR;
