import './all_features.css'
import calendar from './assets/calendar.png'
import experience from './assets/experience.png'
import money from './assets/money.png'
import communication from './assets/247hr-communication.png'
import service from './assets/service.png'
import ratio from './assets/ratio.png'
import timeline from './assets/refresh-time.png'
import features from './assets/new-features.png'
import report from './assets/report.png'
import download_icon from './assets/line-md_download-outline.png'
import newFeature from './assets/new-features.png'
import useServicesStore from '../../Views/Services/storePr'


const All_features = ({ firstPart,middleLetter, lastPart,index}) => {
    const {servicesData, setServicesData} = useServicesStore();
   const data= Object.entries(servicesData[0].packages).map(([pkgIdx,pkgData])=> pkgData.features)
//    const data2= Object.entries(servicesData[0].packages).map(([pkgIdx,pkgData])=> pkgData.name)
//    console.log(data2[0])
   

    return (
        <div id='detail-container' >
            
            <div className='detail-sections' >
            <h4 style={{marginTop:"15px"}}>All Features</h4>
                <div className="detail-section">
                <div className='detail-content marginTop'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={money} /></div>
                        <div className='heading'><p>Price</p></div>
                    </div>
                    <p className='detail-text'>₹{data[0].price}</p>
                </div>
                <div className='detail-content marginTop'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={calendar} /></div>
                        <div className='heading'><p>Media Outlets</p></div>
                    </div>
                    <p className='detail-text'>{data[0].totalMediaOutlets}</p>
                </div>
                </div>
                

                <div className="detail-section">
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={service} /></div>
                        <div className='heading'><p>Press Releasing Writing</p></div>
                    </div>
                    <p className='detail-text'>{data[0].prWriting}</p>
                </div>
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={timeline} /></div>
                        <div className='heading'><p>TAT</p></div>
                    </div>
                    <p className='detail-text'>{data[0].deliveryTime}</p>
                </div>
                </div>
                

                <div className="detail-section">
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={communication} /></div>
                        <div className='heading'><p>Medium of Distribution</p></div>
                    </div>
                    <p className='detail-text'>{data[0].distributionMedium}</p>
                </div>
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={experience} /></div>
                        <div className='heading'><p>Word Limit</p></div>
                    </div>
                    <p className='detail-text'>{data[0].wordsLimit}</p>
                </div>
                </div>

                <div className="detail-section">
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={money} /></div>
                        <div className='heading'><p>Industry Specific</p></div>
                    </div>
                    <p className='detail-text'>{(data[0].industrySpecific) ?`${data[0].industrySpecific}`:"No"}</p>
                </div>
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={calendar} /></div>
                        <div className='heading'><p>Language</p></div>
                    </div>
                    <p className='detail-text'>English,Hindi,Regional Language</p>
                </div>
                </div>

                <div className="detail-section">
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={service} /></div>
                        <div className='heading'><p>Images</p></div>
                    </div>
                    <p className='detail-text'>{data[0].imageIncluded}</p>
                </div>
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={timeline} /></div>
                        <div className='heading'><p>Links</p></div>
                    </div>
                    <p className='detail-text'>{data[0].minimumLinks
                    }</p>
                </div>
                </div>
                
                <div className="detail-section">
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={communication} /></div>
                        <div className='heading'><p>Indexed on Search Engine</p></div>
                    </div>
                    <p className='detail-text'>{data[0].indexOnSearchEngines}</p>
                </div>
                <div className='detail-content'>
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={experience} /></div>
                        <div className='heading'><p> Google News Tagging</p></div>
                    </div>
                    <p className='detail-text'>{data[0].googleNewsTagging}</p>
                </div>
                </div>
                

                <div className='detail-content full-width' id='detail'>
                    <div className='detail-heading'>

                        <div className='symbol'><img className='image' src={newFeature} /></div>
                        <div className='heading'><p id='All-details'> Details of Distribution Network</p></div>
                    </div>
                    <div className='details' style={{gap:"5px"}}>
                    {
                    data[0].networkDetails.map((network,index)=>(
                        <div className='detail-card' key={index} >
                        <p>{network}</p>
                        </div>
                    ))
                    }


                        {/* <div className='detail-card'><p>Business Wire India</p></div> */}
                       

                    </div>
 
                    <div className='detail-heading'>
                        <div className='symbol'><img className='image' src={newFeature} /></div>
                        <div className='heading'><p id='All-details'> Guaranteed Placement</p></div>
                    </div>

                    <div className='details'>
                    {
                        data[0].publishGuarantee?.map((network,index)=>(
                            <div className='detail-card' key={index} >
                            <p>{network}</p>
                            </div>
                        ))
                    }
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default All_features;